export default  [
    {"name_en": "Afghanistan", "code": "af", "name_ar": "أفغانستان"},
    {"name_en": "Albania", "code": "al", "name_ar": "ألبانيا"},
    {"name_en": "Saudi Arabia", "code": "sa", "name_ar": "السعودية"},
    {"name_en": "Sudan", "code": "sd", "name_ar": "السودان"},
    {"name_en": "Algeria", "code": "dz", "name_ar": "الجزائر"},
    {"name_en": "Andorra", "code": "ad", "name_ar": "أندورا"},
    {"name_en": "Angola", "code": "ao", "name_ar": "أنغولا"},
    {"name_en": "Argentina", "code": "ar", "name_ar": "الأرجنتين"},
    {"name_en": "Armenia", "code": "am", "name_ar": "أرمينيا"},
    {"name_en": "Australia", "code": "au", "name_ar": "أستراليا"},
    {"name_en": "Austria", "code": "at", "name_ar": "النمسا"},
    {"name_en": "Azerbaijan", "code": "az", "name_ar": "أذربيجان"},
    {"name_en": "Bahrain", "code": "bh", "name_ar": "البحرين"},
    {"name_en": "Bangladesh", "code": "bd", "name_ar": "بنغلاديش"},
    {"name_en": "Belarus", "code": "by", "name_ar": "بيلاروسيا"},
    {"name_en": "Belgium", "code": "be", "name_ar": "بلجيكا"},
    {"name_en": "Belize", "code": "bz", "name_ar": "بليز"},
    {"name_en": "Benin", "code": "bj", "name_ar": "بنين"},
    {"name_en": "Bhutan", "code": "bt", "name_ar": "بوتان"},
    {"name_en": "Bolivia", "code": "bo", "name_ar": "بوليفيا"},
    {"name_en": "Bosnia and Herzegovina", "code": "ba", "name_ar": "البوسنة والهرسك"},
    {"name_en": "Botswana", "code": "bw", "name_ar": "بوتسوانا"},
    {"name_en": "Brazil", "code": "br", "name_ar": "البرازيل"},
    {"name_en": "Brunei", "code": "bn", "name_ar": "بروناي"},
    {"name_en": "Bulgaria", "code": "bg", "name_ar": "بلغاريا"},
    {"name_en": "Burkina Faso", "code": "bf", "name_ar": "بوركينا فاسو"},
    {"name_en": "Burundi", "code": "bi", "name_ar": "بوروندي"},
    {"name_en": "Cambodia", "code": "kh", "name_ar": "كمبوديا"},
    {"name_en": "Cameroon", "code": "cm", "name_ar": "الكاميرون"},
    {"name_en": "Canada", "code": "ca", "name_ar": "كندا"},
    {"name_en": "Cape Verde", "code": "cv", "name_ar": "الرأس الأخضر"},
    {"name_en": "Central African Republic", "code": "cf", "name_ar": "جمهورية أفريقيا الوسطى"},
    {"name_en": "Chad", "code": "td", "name_ar": "تشاد"},
    {"name_en": "Chile", "code": "cl", "name_ar": "تشيلي"},
    {"name_en": "China", "code": "cn", "name_ar": "الصين"},
    {"name_en": "Colombia", "code": "co", "name_ar": "كولومبيا"},
    {"name_en": "Comoros", "code": "km", "name_ar": "جزر القمر"},
    {"name_en": "Congo", "code": "cg", "name_ar": "الكونغو"},
    {"name_en": "Costa Rica", "code": "cr", "name_ar": "كوستاريكا"},
    {"name_en": "Croatia", "code": "hr", "name_ar": "كرواتيا"},
    {"name_en": "Cuba", "code": "cu", "name_ar": "كوبا"},
    {"name_en": "Cyprus", "code": "cy", "name_ar": "قبرص"},
    {"name_en": "Czech Republic", "code": "cz", "name_ar": "جمهورية التشيك"},
    {"name_en": "Denmark", "code": "dk", "name_ar": "الدنمارك"},
    {"name_en": "Djibouti", "code": "dj", "name_ar": "جيبوتي"},
    {"name_en": "Dominica", "code": "dm", "name_ar": "دومينيكا"},
    {"name_en": "Dominican Republic", "code": "do", "name_ar": "جمهورية الدومينيكان"},
    {"name_en": "Ecuador", "code": "ec", "name_ar": "الإكوادور"},
    {"name_en": "Egypt", "code": "eg", "name_ar": "مصر"},
    {"name_en": "Tunisia", "code": "tn", "name_ar": "تونس"},
    {"name_en": "El Salvador", "code": "sv", "name_ar": "السلفادور"},
    {"name_en": "Equatorial Guinea", "code": "gq", "name_ar": "غينيا الاستوائية"},
    {"name_en": "Eritrea", "code": "er", "name_ar": "إريتريا"},
    {"name_en": "Estonia", "code": "ee", "name_ar": "إستونيا"},
    {"name_en": "Eswatini", "code": "sz", "name_ar": "إسواتيني"},
    {"name_en": "Ethiopia", "code": "et", "name_ar": "إثيوبيا"},
    {"name_en": "Fiji", "code": "fj", "name_ar": "فيجي"},
    {"name_en": "Finland", "code": "fi", "name_ar": "فنلندا"},
    {"name_en": "France", "code": "fr", "name_ar": "فرنسا"},
    {"name_en": "Gabon", "code": "ga", "name_ar": "الغابون"},
    {"name_en": "Gambia", "code": "gm", "name_ar": "غامبيا"},
    {"name_en": "Georgia", "code": "ge", "name_ar": "جورجيا"},
    {"name_en": "Germany", "code": "de", "name_ar": "ألمانيا"},
    {"name_en": "Ghana", "code": "gh", "name_ar": "غانا"},
    {"name_en": "Greece", "code": "gr", "name_ar": "اليونان"},
    {"name_en": "Grenada", "code": "gd", "name_ar": "غرينادا"},
    {"name_en": "Guatemala", "code": "gt", "name_ar": "غواتيمالا"},
    {"name_en": "Guinea", "code": "gn", "name_ar": "غينيا"},
    {"name_en": "Guinea-Bissau", "code": "gw", "name_ar": "غينيا بيساو"},
    {"name_en": "Guyana", "code": "gy", "name_ar": "غيانا"},
    {"name_en": "Haiti", "code": "ht", "name_ar": "هايتي"},
    {"name_en": "Honduras", "code": "hn", "name_ar": "هندوراس"},
    {"name_en": "Hungary", "code": "hu", "name_ar": "المجر"},
    {"name_en": "Iceland", "code": "is", "name_ar": "آيسلندا"},
    {"name_en": "India", "code": "in", "name_ar": "الهند"},
    {"name_en": "Indonesia", "code": "id", "name_ar": "أندونيسيا"},
    {"name_en": "Iran", "code": "ir", "name_ar": "إيران"},
    {"name_en": "Iraq", "code": "iq", "name_ar": "العراق"},
    {"name_en": "Ireland", "code": "ie", "name_ar": "أيرلندا"},
    {"name_en": "Israel", "code": "il", "name_ar": "إسرائيل"},
    {"name_en": "Italy", "code": "it", "name_ar": "إيطاليا"},
    {"name_en": "Jamaica", "code": "jm", "name_ar": "جامايكا"},
    {"name_en": "Japan", "code": "jp", "name_ar": "اليابان"},
    {"name_en": "Jordan", "code": "jo", "name_ar": "الأردن"},
    {"name_en": "Kazakhstan", "code": "kz", "name_ar": "كازاخستان"},
    {"name_en": "Kenya", "code": "ke", "name_ar": "كينيا"},
    {"name_en": "Kiribati", "code": "ki", "name_ar": "كيريباتي"},
    {"name_en": "Korea, North", "code": "kp", "name_ar": "كوريا الشمالية"},
    {"name_en": "Korea, South", "code": "kr", "name_ar": "كوريا الجنوبية"},
    {"name_en": "Kuwait", "code": "kw", "name_ar": "الكويت"},
    {"name_en": "Kyrgyzstan", "code": "kg", "name_ar": "قيرغيزستان"},
    {"name_en": "Laos", "code": "la", "name_ar": "لاوس"},
    {"name_en": "Latvia", "code": "lv", "name_ar": "لاتفيا"},
    {"name_en": "Lebanon", "code": "lb", "name_ar": "لبنان"},
    {"name_en": "Lesotho", "code": "ls", "name_ar": "ليسوتو"},
    {"name_en": "Liberia", "code": "lr", "name_ar": "ليبيريا"},
    {"name_en": "Libya", "code": "ly", "name_ar": "ليبيا"},
    {"name_en": "Liechtenstein", "code": "li", "name_ar": "ليختنشتاين"},
    {"name_en": "Lithuania", "code": "lt", "name_ar": "ليتوانيا"},
    {"name_en": "Luxembourg", "code": "lu", "name_ar": "لوكسمبورغ"},
    {"name_en": "Madagascar", "code": "mg", "name_ar": "مدغشقر"},
    {"name_en": "Malawi", "code": "mw", "name_ar": "مالاوي"},
    {"name_en": "Malaysia", "code": "my", "name_ar": "ماليزيا"},
    {"name_en": "Maldives", "code": "mv", "name_ar": "جزر المالديف"},
    {"name_en": "Mali", "code": "ml", "name_ar": "مالي"},
    {"name_en": "Malta", "code": "mt", "name_ar": "مالطا"},
    {"name_en": "Marshall Islands", "code": "mh", "name_ar": "جزر مارشال"},
    {"name_en": "Mauritania", "code": "mr", "name_ar": "موريتانيا"},
    {"name_en": "Mauritius", "code": "mu", "name_ar": "موريشيوس"},
    {"name_en": "Mexico", "code": "mx", "name_ar": "المكسيك"},
    {"name_en": "Micronesia", "code": "fm", "name_ar": "ميكرونيزيا"},
    {"name_en": "Moldova", "code": "md", "name_ar": "مولدوفا"},
    {"name_en": "Monaco", "code": "mc", "name_ar": "موناكو"},
    {"name_en": "Mongolia", "code": "mn", "name_ar": "منغوليا"},
    {"name_en": "Montenegro", "code": "me", "name_ar": "الجبل الأسود"},
    {"name_en": "Morocco", "code": "ma", "name_ar": "المغرب"},
    {"name_en": "Mozambique", "code": "mz", "name_ar": "موزمبيق"},
    {"name_en": "Myanmar", "code": "mm", "name_ar": "ميانمار"},
    {"name_en": "Namibia", "code": "na", "name_ar": "ناميبيا"},
    {"name_en": "Nauru", "code": "nr", "name_ar": "ناورو"},
    {"name_en": "Nepal", "code": "np", "name_ar": "نيبال"},
    {"name_en": "Netherlands", "code": "nl", "name_ar": "هولندا"},
    {"name_en": "New Zealand", "code": "nz", "name_ar": "نيوزيلندا"},
    {"name_en": "Nicaragua", "code": "ni", "name_ar": "نيكاراغوا"},
    {"name_en": "Niger", "code": "ne", "name_ar": "النيجر"},
    {"name_en": "Nigeria", "code": "ng", "name_ar": "نيجيريا"},
    {"name_en": "North Macedonia", "code": "mk", "name_ar": "مقدونيا الشمالية"},
    {"name_en": "Norway", "code": "no", "name_ar": "النرويج"},
    {"name_en": "Oman", "code": "om", "name_ar": "عمان"},
    {"name_en": "Pakistan", "code": "pk", "name_ar": "باكستان"},
    {"name_en": "Palau", "code": "pw", "name_ar": "بالاو"},
    {"name_en": "Panama", "code": "pa", "name_ar": "بنما"},
    {"name_en": "Papua New Guinea", "code": "pg", "name_ar": "بابوا غينيا الجديدة"},
    {"name_en": "Paraguay", "code": "py", "name_ar": "باراغواي"},
    {"name_en": "Peru", "code": "pe", "name_ar": "بيرو"},
    {"name_en": "Philippines", "code": "ph", "name_ar": "الفلبين"},
    {"name_en": "Poland", "code": "pl", "name_ar": "بولندا"},
    {"name_en": "Portugal", "code": "pt", "name_ar": "البرتغال"},
    {"name_en": "Qatar", "code": "qa", "name_ar": "قطر"},
    {"name_en": "Syria", "code": "sy", "name_ar": "سوريا"},
    {"name_en": "Jordan", "code": "jo", "name_ar": "الاردن"},
    {"name_en": "Palestine", "code": "ps", "name_ar": "فلسطين"},
    {"name_en": "Romania", "code": "ro", "name_ar": "رومانيا"},
    {"name_en": "Russia", "code": "ru", "name_ar": "روسيا"},
    {"name_en": "Rwanda", "code": "rw", "name_ar": "رواندا"},
    {"name_en": "Saint Kitts and Nevis", "code": "kn", "name_ar": "سانت كيتس ونيفيس"},
    {"name_en": "Saint Lucia", "code": "lc", "name_ar": "سانت لوسيا"},
    {"name_en": "Saint Vincent and the Grenadines", "code": "vc", "name_ar": "سانت فنسنت والغرينادين"},
    {"name_en": "Samoa", "code": "ws", "name_ar": "ساموا"},
    {"name_en": "San Marino", "code": "sm", "name_ar": "سان مارينو"},
    {"name_en": "Sao Tome and Principe", "code": "st", "name_ar": "ساو تومي وبرينسيبي"},
    {"name_en": "Saudi Arabia", "code": "sa", "name_ar": "السعودية"},
    {"name_en": "Senegal", "code": "sn", "name_ar": "السنغال"},
    {"name_en": "Serbia", "code": "rs", "name_ar": "صربيا"},
    {"name_en": "Seychelles", "code": "sc", "name_ar": "سيشل"},
    {"name_en": "Sierra Leone", "code": "sl", "name_ar": "سيراليون"},
    {"name_en": "Singapore", "code": "sg", "name_ar": "سنغافورة"},
    {"name_en": "Slovakia", "code": "sk", "name_ar": "سلوفاكيا"},
    {"name_en": "Slovenia", "code": "si", "name_ar": "سلوفينيا"},
    {"name_en": "Solomon Islands", "code": "sb", "name_ar": "جزر سليمان"},
];