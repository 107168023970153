import { useQuery } from "react-query";

async function getResources() {
    const response = await fetch(
      `${process.env.REACT_APP_API_KEY}api/v1/resources/status/accept-resources`
    );
    const data = await response.json();
    return data;
  }

  export const useResources = () => {
    return useQuery("get-resources", getResources);
  };
