import React, { useEffect, useState } from 'react';
import FormControl from '@mui/material/FormControl';
import { Container, TextField, Select } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import { t } from 'i18next';
import { useForm } from 'react-hook-form';
import Cookies from 'js-cookie';
import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import LoadingButton from '@mui/lab/LoadingButton';
import axios from 'axios';
import currencies from '../../data/currencies';
import Navbar from '../../components/Navbar';
import { useLevels } from '../../hooks/useLevels';
import Loading from '../../components/Loading';


function AddResources() {
  const lang = Cookies.get("i18next") || "en";
  const [levels, setLevels] = useState([])
  const [subjects, setSubjects] = useState([])
  const { data, isLoading } = useLevels()
  const { enqueueSnackbar } = useSnackbar();
  const [file, setFile] = useState(null);
  const [resource, setResource] = useState(false);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { teacher } = useSelector((state) => state.teacher);

  useEffect(() => {
    if (data?.data) {
      setLevels(data.data);
    }
    const getSubjects = async () => {
      const response = await axios.get(`${process.env.REACT_APP_API_KEY}api/v1/subject/allSubjects`);
      setSubjects(response.data.data);
    };
    getSubjects();
  }, [data]);

  const { register, handleSubmit, formState: { errors } } = useForm({
    subject: '',
    level: '',
    resource: '',
    video: '',
    price: '',
    currency: '',
    teacherId: '',
    startDate: '',
    endDate: '',
    duration: '',
  });
  function handleFile(e) {
    const tmpFile = e.target.files[0];
    if (/application\/pdf|image\/*/.test(tmpFile.type)) {
      setFile(e.target.files[0]);
    }
  }
  async function createResources(data) {
    const startDateTime = new Date(data.startDate); // تحويل إلى كائن تاريخ
    const endDateTime = new Date(data.endDate); // تحويل إلى كائن تاريخ
    const differenceInMilliseconds = endDateTime - startDateTime;
    const differenceInSeconds = Math.floor(differenceInMilliseconds / (1000 * 60 * 60 * 24));
    // تحقق من أن startDate أقل من endDate
    if (startDateTime >= endDateTime) {
      enqueueSnackbar(t("Start date must be earlier than end date"), { variant: "error", autoHideDuration: 5000 });
      return; // إنهاء الوظيفة إذا كان الشرط غير محقق
    }
    data.duration=differenceInSeconds
    data.startDate = startDateTime;
    data.endDate = endDateTime;
    const tmpData = { ...data, teacherId: teacher.id };
    const formData = new FormData();
    formData.append("image", file);
    for (const key in tmpData) {
      formData.append(key, tmpData[key]);
    }
    try {
      await axios.post(`${process.env.REACT_APP_API_KEY}api/v1/resources/create-resource`, formData);
      setLoading(true);
      enqueueSnackbar(t("packageMsg"), { variant: "success", autoHideDuration: "5000" });
      navigate("/teacher/resources")
    } catch (err) {
      enqueueSnackbar("data failed", { variant: "error", autoHideDuration: "5000" });
      setLoading(false);
      throw new Error("Something went wrong's " + err);
    }
  }

  return (
    <Navbar>
      <Container sx={{ marginTop: '120px', marginBottom: '80px' }}>
        {(!isLoading) ? <>
          <h1 className="text-3xl text-center my-4">{t("addResource")}</h1>
          <form style={{ maxWidth: "50%", margin: 'auto' }} encType="multipart/form-data" onSubmit={handleSubmit(createResources)}>
            <FormControl fullWidth margin="dense">
              <InputLabel id="level">{t("subject")}</InputLabel>
              <Select
                labelId="subject"
                label={t("subject")}
                defaultValue="" // قيمة افتراضية
                {...register("subject", {
                  required: t("isRequired"),
                })}
              >
                {lang === "ar"
                  ? subjects.map((subject, index) => (
                    <MenuItem key={index} value={subject.id}>{t(subject.titleAR)}</MenuItem>
                  ))
                  : subjects.map((subject, index) => (
                    <MenuItem key={index} value={subject.id}>{t(subject.titleEN)}</MenuItem>
                  ))
                }
              </Select>
              <p className="text-red-500">{errors.subject?.message}</p>
            </FormControl>
            <FormControl fullWidth margin="dense">
              <InputLabel id="level">{t("choosesLevel")}</InputLabel>
              <Select
                labelId="level"
                label={t("choosesLevel")}
                defaultValue="" // قيمة افتراضية
                {...register("level", {
                  required: t("isRequired"),
                })}
              >
                {lang === "ar"
                  ? levels.map((level, index) => (
                    <MenuItem key={index} value={level.id}>{t(level.titleAR)}</MenuItem>
                  ))
                  : levels.map((level, index) => (
                    <MenuItem key={index} value={level.id}>{t(level.titleEN)}</MenuItem>
                  ))
                }
              </Select>
              <p className="text-red-500">{errors.level?.message}</p>
            </FormControl>

            <FormControl fullWidth margin="dense">
              <InputLabel id="resource">{t("resource")}</InputLabel>
              <Select
                onClick={() => setResource(true)}
                labelId="resource"
                label={t("resource")}
                {...register("resource", {
                  required: t("isRequired"),
                })}
              >
                <MenuItem value="Lecture">{t("Lecture")}</MenuItem>
                <MenuItem value="Exam">{t("Exam")}</MenuItem>
                <MenuItem value="Reviews">{t("Reviews")}</MenuItem>
                <MenuItem value="Summary of the material">{t("Summary of the material")}</MenuItem>
              </Select>
              <p className='text-red-500'>{errors.resource?.message}</p>
            </FormControl>
            {
              resource ? <FormControl fullWidth margin='dense'>
                <TextField type="text" label={t("link")} {...register("video", {
                  required: {
                    // value: !file,
                    message: t("file_video-validation")
                  },
                  pattern: {
                    value: /^(https?:\/\/)?(www\.youtube\.com|youtu\.be)\/.+$/,
                    message: t("youtube_link")
                  }
                })} />
                {/* <TextField sx={{ marginTop: "10px" }} type="file" required={!(!errors.video?.message)} label={t("file")} onChange={(e) => handleFile(e)} /> */}
                <p className='text-red-500'>{errors.video?.message}</p>
              </FormControl>
                : ""
            }
            <FormControl fullWidth margin="dense">
              <TextField
                label={t("startDate")}
                type="date"
                variant="outlined"
                {...register("startDate", {
                  required: t("isRequired"),
                })} />
              <p className='text-red-500'>{errors.startDate?.message}</p>
            </FormControl>
            <FormControl fullWidth margin="dense">
              <TextField
                label={t("End Date")}
                type="date"
                variant="outlined"
                {...register("endDate", {
                  required: t("isRequired"),
                })} />
              <p className='text-red-500'>{errors.endDate?.message}</p>
            </FormControl>
            <FormControl fullWidth margin="dense">
              <TextField
                label={t("price")}
                type="number"
                variant="outlined"
                {...register("price", {
                  required: t("isRequired"),
                })} />
              <p className='text-red-500'>{errors.price?.message}</p>
            </FormControl>
            <FormControl fullWidth margin="dense">
              <InputLabel id="currency">{t("currency")}</InputLabel>
              <Select
                labelId="currency"
                label={t("currency")}
                {...register("currency", {
                  required: t("isRequired"),
                })}
              >
                {
                  currencies.map((curr) => {
                    return <MenuItem value={curr.title}>{lang === "en" ? curr.titleEn : curr.titleAr}</MenuItem>
                  })
                }
              </Select>
              <p className='text-red-500'>{errors.currency?.message}</p>
            </FormControl>
            <FormControl fullWidth margin='dense'>
              <InputLabel id="image">{t("chooseImage")}</InputLabel><br /><br />
              <input type="file" onChange={handleFile} />
            </FormControl>
            <br />
            <br />
            <LoadingButton
              type="submit"
              loading={loading}
              loadingPosition="start"
              variant="contained"
            >{t("add")}</LoadingButton>
          </form>
        </> : <Loading />}

      </Container>
    </Navbar>
  )
}

export default AddResources
