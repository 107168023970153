import { useEffect, useState } from "react";
import { t } from "i18next";
import Container from "@mui/material/Container";
import { Grid, Card, CardContent, Typography } from "@mui/material";
import { useCount } from "../../../hooks/useCount";
import student from '../../../images/student.svg'
import teacher from '../../../images/teacher.svg'
import lesson from '../../../images/lesson.svg'
import lecture from '../../../images/lecture.svg'


function NumberTab() {
  const { data } = useCount();
  const [dataCount, setDataCount] = useState(null); // ✅ تخزين البيانات باستخدام useState

  useEffect(() => {
    if (data?.data) {
      setDataCount(data.data);
    }
  }, [data]);

  return (
    <Container sx={{
      marginTop:{md:18 , xs:5}
    }}>
      <Grid container spacing={2} sx={{ padding: 2 }}>
        <Grid item xs={12} sm={6} md={4} lg={6}>
          <Card sx={{textAlign: "center", boxShadow: 3, borderRadius: "20px", background: "#3B82F6ED",display: "flex",
                justifyContent: "center",
                alignItems: "center", }}>
            <CardContent >
              <Typography  component="div" sx={{
                background: "#FFF",
                borderRadius: "50%",
                width: "100px",
                height: "100px",
              }}>
                <img src={student} alt={student}  width={100} height={100}/>
              </Typography>
              <Typography variant="h6" component="div" className="text-[#fff] font-bold">
                {dataCount?.students || 0}
              </Typography>
              <Typography variant="body2" className="text-[#fff] font-bold">
                {t("students")}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={6}>
          <Card sx={{ textAlign: "center", boxShadow: 3, borderRadius: "20px", background: "#3B82F6ED",display: "flex",
                justifyContent: "center",
                alignItems: "center", }}>
            <CardContent >
              <Typography  component="div" sx={{
                background: "#FFF",
                borderRadius: "50%",
                width: "100px",
                height: "100px",
              }}>
                <img src={teacher} alt={teacher}  width={100} height={100}/>
              </Typography>
              <Typography variant="h6" component="div" className="text-[#fff] font-bold">
                {dataCount?.teachers || 0}
              </Typography>
              <Typography variant="body2" className="text-[#fff] font-bold">
                {t("teachers")}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={6}>
          <Card sx={{ textAlign: "center", boxShadow: 3, borderRadius: "20px", background: "#3B82F6ED",display: "flex",
                justifyContent: "center",
                alignItems: "center", }}>
            <CardContent >
              <Typography  component="div" sx={{
                background: "#FFF",
                borderRadius: "50%",
                width: "100px",
                height: "100px",
              }}>
                <img src={lecture} alt={lecture}  width={100} height={100}/>
              </Typography>
              <Typography variant="h6" component="div" className="text-[#fff] font-bold">
                {dataCount?.lectures || 0}
              </Typography>
              <Typography variant="body2" className="text-[#fff] font-bold">
                {t("Lecture")}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={6}>
          <Card sx={{ textAlign: "center", boxShadow: 3, borderRadius: "20px", background: "#3B82F6ED",display: "flex",
                justifyContent: "center",
                alignItems: "center", }}>
            <CardContent >
              <Typography  component="div" sx={{
                background: "#FFF",
                borderRadius: "50%",
                width: "100px",
                height: "100px",
              }}>
                <img src={lesson} alt={lesson}  width={100} height={100}/>
              </Typography>
              <Typography variant="h6" component="div" className="text-[#fff] font-bold">
                {dataCount?.lessons || 0}
              </Typography>
              <Typography variant="body2" className="text-[#fff] font-bold">
                {t("lessons")}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
}

export default NumberTab;
