import {
    Box,
    Button,
    DialogActions,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { useSelector } from "react-redux";
import Cookies from "js-cookie";
import { useAllQuestions } from "../../hooks/useAllQuestions";
import Loading from "../Loading";
import axios from "axios";
import { useNavigate } from "react-router-dom";

export default function AddAnswer() {
    const { t } = useTranslation();
    const { closeSnackbar, enqueueSnackbar } = useSnackbar();
    const [questions,setQuestions] = useState([]);
    const { teacher } = useSelector((state) => state.teacher);
    const navigate = useNavigate();

    const {
        register,
        control,
        formState: { errors },
        handleSubmit,
    } = useForm({
        defaultValues: {
            questionId: "",
            titleArabic: "",
            titleEnglish: "",
            descriptionArabic: "",
            descriptionEnglish: ""
        },
    });

    const { data, isLoading } = useAllQuestions(teacher.id);
    useEffect(() => {
            if (data?.data) {
                setQuestions(data.data);
            }
        }, [data]);

    const lang = Cookies.get("i18next") || "en";
    async function onSubmit(data) {
        closeSnackbar();
        const formData = new FormData();

        formData.append("teacherId", teacher.id);
        for (const key in data) {
            if (key !== "teacherId") {
                formData.append(key, data[key]);
            }
        }

        try {
            await axios.post(
                `${process.env.REACT_APP_API_KEY}api/v1/teacher/answers/create-answer`,
                formData,
                {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                }
            );

            enqueueSnackbar("A new Answer has been created.", { variant: "success", autoHideDuration: 8000 });
            navigate("/teacher/answers")
        } catch (err) {
            enqueueSnackbar("Something went wrong", { variant: "error", autoHideDuration: 8000 });
        }
    }
    return (
        <>
           {
            !isLoading?
            <>
            <Box sx={{ width: "500px", maxWidth: "100%" }}>
                <form onSubmit={handleSubmit(onSubmit)}>
                                <Box sx={{ marginBottom: "18px" }}>
                                    <InputLabel sx={{ marginBottom: "6px", fontSize: "14px" }}>
                                        {t("Select Question")}
                                    </InputLabel>
                                    <Controller
                                        name="questionId"
                                        control={control}
                                        render={({ field }) => (
                                            <Select
                                                {...field}
                                                fullWidth
                                                {...register("questionId", {
                                                    required: t("isRequired"),
                                                })}
                                            >
                                                {lang === "ar"
                                                    ? questions.map((question, index) => (
                                                        <MenuItem key={index} value={question.id}>{t(question.titleArabic)}</MenuItem>
                                                    ))
                                                    : questions.map((question, index) => (
                                                        <MenuItem key={index} value={question.id}>{t(question.tittitleEnglish)}</MenuItem>
                                                    ))
                                                }
                                            </Select>
                                        )}
                                        rules={{ required: t("required") }}
                                    />

                                </Box>
                    <Box >
                        <InputLabel sx={{ marginBottom: "6px", fontSize: "14px" }}>
                            {t("titleAr")}
                        </InputLabel>
                        <Controller
                            name="titleArabic"
                            control={control}
                            render={({ field }) => <TextField {...field} fullWidth />}
                            {...register("titleArabic", {
                                required: "title Address is required",
                            })}
                        />
                        {errors.titleArabic?.type === "required" && (
                            <Typography
                                color="error"
                                role="alert"
                                sx={{ fontSize: "13px", marginTop: "6px" }}
                            >
                                {t("required")}
                            </Typography>
                        )}
                    </Box>
                    <Box sx={{ marginBottom: "18px" }}>
                        <InputLabel sx={{ marginBottom: "6px", fontSize: "14px" }}>
                            {t("titleEn")}
                        </InputLabel>
                        <Controller
                            name="titleEnglish"
                            control={control}
                            render={({ field }) => <TextField {...field} fullWidth />}
                            {...register("titleEnglish", {
                                required: "title Address is required",
                            })}
                        />
                        {errors.titleEnglish?.type === "required" && (
                            <Typography
                                color="error"
                                role="alert"
                                sx={{ fontSize: "13px", marginTop: "6px" }}
                            >
                                {t("required")}
                            </Typography>
                        )}
                    </Box>
                    <Box sx={{ marginBottom: "18px" }}>
                        <FormControl fullWidth margin="dense">
                            <InputLabel sx={{ marginBottom: "6px", fontSize: "14px" }}>
                                {t("Is the answer correct?")}
                            </InputLabel>
                            <Select
                                labelId="answerStatus"
                                label={t("answerStatus")}
                                defaultValue="" // قيمة افتراضية
                                {...register("answerStatus", {
                                    required: t("isRequired"),
                                })}
                            >
                                <MenuItem value={"Yes"}>{t("Yes")}</MenuItem>
                                <MenuItem value={"No"}>{t("No")}</MenuItem>
                            </Select>
                        </FormControl>
                    </Box>
                    <DialogActions>
                        <Button
                            variant="contained"
                            type="submit"
                            sx={{ ml: "6px", mr: "6px" }}
                        >
                            {t("save")}
                        </Button>
                    </DialogActions>
                </form>

            </Box>
            </> : <>
            <Loading />
            </>
           }

        </>
    );
}
