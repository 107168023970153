import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import AdminLayout from '../../components/admin/AdminLayout';
import { t } from 'i18next';
import instance from './../../axios/instance';
import { Button } from '@mui/material';
import { useSnackbar } from "notistack";
import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import Cookies from "js-cookie";

function AdminPackages() {
  const [search, setSearch] = useState("");
  const [packages, setPackages] = useState([]);
  const [teachers, setTeachers] = useState([]);

  function packageSearch(e) {
    const eleValue = e.target.value;
    setSearch(eleValue);
  }
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    (async () => {
      try {
        // Get all packages
        const response = await instance.get("packages/get-packages");
        const data = response.data.data;
        setPackages(data);
      } catch (error) {
        console.error("Error fetching packages or teachers:", error);
      }
    })();
  }, []);
  async function deletePackage(id) {
    try {
      await instance.delete(`packages/delete-package/${id}`);
      enqueueSnackbar(t("deleteSuccess"), { variant: "success", autoHideDuration: 8000 });
    } catch {
      enqueueSnackbar(t("deleteError"), { variant: "error", autoHideDuration: 8000 });
    }
  }
  const lang = Cookies.get("i18next") || "en";

  return (
    <AdminLayout>
      <TableContainer component={Paper}>
        <Paper
          sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: "100%" }}
        >
          <InputBase
            sx={{ ml: 1, flex: 1 }}
            placeholder={t("adminSearchPackage")}
            onChange={(e) => { packageSearch(e) }}
          />
          <IconButton type="button" sx={{ p: '10px' }} aria-label="search">
            <SearchIcon />
          </IconButton>
        </Paper>
        <Table  >
          <TableHead>
            <TableRow>
              <StyledTableCell>{t("packName")}</StyledTableCell>
              <StyledTableCell >{t("teacherName")}</StyledTableCell>
              <StyledTableCell >{t("level")}</StyledTableCell>
              <StyledTableCell >{t("price")}</StyledTableCell>
              <StyledTableCell >{t("currency")}</StyledTableCell>
              <StyledTableCell >{t("delete")}</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {packages.length > 0 ? (
              packages.map((pack, i) => (<>
                <StyledTableRow>
                  <StyledTableCell >{pack.teacher.firstName + " " + pack.teacher.lastName}</StyledTableCell>
                  <StyledTableCell >{
                    lang === "ar" ?
                      <>{pack.titleAr}</> : <>{pack.titleEn}</>
                  }</StyledTableCell>
                  <StyledTableCell >{
                    lang === "ar" ?
                      <>{pack.level.titleAR}</> : <>{pack.level.titleEN}</>
                  }</StyledTableCell>
                  <StyledTableCell >{pack.price}</StyledTableCell>
                  <StyledTableCell >{t(pack.currency)}</StyledTableCell>

                  <StyledTableCell >                  
                    <Button
                    variant="contained"
                    color="error"
                    onClick={() => deletePackage(pack.id)}
                  >
                    {t("delete")}
                  </Button></StyledTableCell>

                </StyledTableRow>
              </>))) : ""}

          </TableBody>
        </Table>
      </TableContainer>
    </AdminLayout>
  );
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

export default AdminPackages;
