import { useState } from "react";
import AdminLayout from "../../components/admin/AdminLayout";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { Box, Button, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import Loading from "../../components/Loading";
import { useAdminLessons } from "../../hooks/useAdminLessons";
import { useSelector } from "react-redux";
import Moment from "moment";
import TextField from "@mui/material/TextField";
import { useNavigate } from "react-router-dom";
import jsPDF from "jspdf";

export default function AdminBookedLessons() {
  const { token } = useSelector((state) => state.admin);
  const { t } = useTranslation();
  const { data, isLoading } = useAdminLessons(token);
  const columns = [
    { id: "id", label: t("Invoice number"), minWidth: 150 },
    { id: "Name", label: t("teacher"), minWidth: 150 },
    { id: "Email", label: t("student"), minWidth: 150 },
    { id: "session_number", label: t("Session number"), minWidth: 150 },
    { id: "price", label: t("price"), minWidth: 150 },
    { id: "Phone", label: t("currency"), minWidth: 150 },
    { id: "bookingDate", label: t("bookingDate"), minWidth: 150 },
    { id: "payment", label: t("payment"), minWidth: 150 },
    { id: "where", label: t("where"), minWidth: 150 },
    { id: "confirmTeacher", label: t("confirmTeacher"), minWidth: 150 },
    { id: "confirmStudent", label: t("confirmStudent"), minWidth: 150 },
    { id: "actions", label: t("actions"), minWidth: 300 },
  ];
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchInput, setSearchInput] = useState("");
  const navigate = useNavigate();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  function convertDate(dateString) {
    const date = new Date(dateString);
    return date.toISOString().split('T')[0]; // yyyy-mm-dd
  }

  const generatePDF = (row) => {
    const doc = new jsPDF();
    const categories = row.Teacher.TeacherSubjects.map(ts => ts.Subject.SubjectCategory);
    const uniqueCategories = Array.from(new Map(categories.map(cat => [cat?.id, cat])).values());
    const teacherSubjects = uniqueCategories
            .map(ts => ts?.titleEN)
            .filter(Boolean)
            .join(", ");
    // تحميل اللوجو
    const logo = "/logo.png"; // استخدم مسار اللوجو في `public` أو رابط مباشر للصورة
    doc.addImage(logo, "PNG", 160, 7, 15, 15); // تحديد الموقع والحجم (X, Y, Width, Height)

    // إضافة اسم الموقع
    doc.setFont("helvetica", "bold");
    doc.setFontSize(15);
    doc.setTextColor(52, 152, 219);
    doc.text("Moalime", 20, 20);

    doc.setFontSize(16);
    doc.setTextColor(255, 0, 0);
    doc.text("Session Billing Report", 75, 30);

    doc.setFont("helvetica", "normal");
    doc.setTextColor(0, 0, 0);
    doc.setFontSize(12);

    let y = 50;
    doc.text(`Your session with your teacher ${row?.Teacher.firstName} ${row?.Teacher.lastName} has been successfully booked.`, 14, y, {maxWidth: 180});
    y += 10;
    doc.text(`These are the specializations of the teacher who will give you the class`, 14, y, {maxWidth: 180});
    y += 10;
    doc.text(`( ${teacherSubjects} )`, 14, y, {maxWidth: 180});
    y += 20;
    doc.setFontSize(12);
    doc.text(`Invoice Number: ${row.id}`, 14, y);
    y += 10;
    doc.text(`Date: ${convertDate(row.date)}`, 14, y);
    y += 10;
    doc.text(`Session Number: ${row.period}`, 14, y);
    y += 10;
    doc.text(`Price: ${row.price} ${row.currency}`, 14, y);
    y += 10;
    doc.text(`Teacher: ${row.Teacher?.firstName || "N/A"} ${row.Teacher?.lastName || "N/A"}`, 14, y);
    y += 10;
    doc.text(`Teacher Phone: ${row.Teacher?.phone || "N/A"}`, 14, y);
    y += 10;
    doc.text(`Teacher Email: ${row.Teacher?.email || "N/A"}`, 14, y);
    y += 10;
    doc.text(`Student: ${row.Student?.name || "N/A"}`, 14, y);
    y += 10;
    doc.text(`Student Phone: ${row.Student?.phoneNumber || "N/A"}`, 14, y);
    y += 10;
    doc.text(`Student Email: ${row.Student?.email || "N/A"}`, 14, y);
    y += 140;
    doc.text(`Moalime platform, I wish you success always`, 60, y);

    doc.save(`Session Billing Report.pdf`);
  };
  const printPDF = (session) => {
    const doc = new jsPDF();
    const categories = session.Teacher.TeacherSubjects.map(ts => ts.Subject.SubjectCategory);
    console.log(categories);
        const uniqueCategories = Array.from(new Map(categories.map(cat => [cat?.id, cat])).values());
        const teacherSubjects = uniqueCategories
            .map(ts => ts?.titleEN)
            .filter(Boolean)
            .join(", ");
    
    // تحميل اللوجو
    const logo = "/logo.png"; // استخدم مسار اللوجو في `public` أو رابط مباشر للصورة
    doc.addImage(logo, "PNG", 160, 7, 15, 15); // تحديد الموقع والحجم (X, Y, Width, Height)

    // إضافة اسم الموقع
    doc.setFont("helvetica", "bold");
    doc.setFontSize(15);
    doc.setTextColor(52, 152, 219);
    doc.text("Moalime", 20, 20);

    doc.setFontSize(16);
    doc.setTextColor(255, 0, 0);
    doc.text("Session Billing Report", 75, 30);

    doc.setFont("helvetica", "normal");
    doc.setTextColor(0, 0, 0);
    doc.setFontSize(12);

    let y = 50;
        
    // النص الذي يلتف (wrapping text) داخل العرض المحدد
    doc.text(`Your session with your teacher ${session?.Teacher.firstName} ${session?.Teacher.lastName} has been successfully booked.`, 14, y, {maxWidth: 180});
    y += 10;
    doc.text(`These are the specializations of the teacher who will give you the class`, 14, y, {maxWidth: 180});
    y += 10;
    doc.text(`( ${teacherSubjects} )`, 14, y, {maxWidth: 180});
    y += 20;
    doc.setFontSize(12);
    doc.text(`Invoice Number: ${session.id}`, 14, y);
    y += 10;
    doc.text(`Date: ${convertDate(session.date)}`, 14, y);
    y += 10;
    doc.text(`Session Number: ${session.period}`, 14, y);
    y += 10;
    doc.text(`Price: ${session.price} ${session.currency}`, 14, y);
    y += 10;
    doc.text(`Teacher: ${session.Teacher?.firstName || "N/A"} ${session.Teacher?.lastName || "N/A"}`, 14, y);
    y += 10;
    doc.text(`Teacher Phone: ${session.Teacher?.phone || "N/A"}`, 14, y);
    y += 10;
    doc.text(`Teacher Email: ${session.Teacher?.email || "N/A"}`, 14, y);
    y += 10;
    doc.text(`Student: ${session.Student?.name || "N/A"}`, 14, y);
    y += 10;
    doc.text(`Student Phone: ${session.Student?.phoneNumber || "N/A"}`, 14, y);
    y += 10;
    doc.text(`Student Email: ${session.Student?.email || "N/A"}`, 14, y);
    y += 130;
    doc.text(`Moalime platform, I wish you success always`, 60, y);
    doc.autoPrint();
    window.open(doc.output("bloburl"), "_blank");
  };
  return (
    <AdminLayout>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "30px",
          marginTop: "20px",
        }}
      >
        <Typography sx={{ fontSize: "20px", fontWeight: "500" }}>
          {t("bookedLessons")}
        </Typography>
      </Box>
      {!isLoading ? (
        <Paper sx={{ width: "100%", padding: "20px" }}>
          <TableContainer sx={{ maxHeight: 440 }}>
            <TextField
              sx={{ m: 1, width: "90%" }}
              label={t("search")}
              variant="outlined"
              value={searchInput}
              onChange={(e) => setSearchInput(e.target.value)}
            />
            <Table stickyHeader aria-label="sticky table">
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={"center"}
                    style={{ top: 57, minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
              <TableBody>
                {data?.data.length > 0 &&
                  data?.data
                    .filter(
                      (row) =>
                        `${row.Teacher?.firstName +
                          " " +
                          row.Teacher?.lastName || t("username")
                          }`
                          .toLowerCase()
                          .includes(searchInput.toLowerCase().trim()) ||
                        `${row.Student?.name || t("username")}`
                          .toLowerCase()
                          .includes(searchInput.toLowerCase().trim()) ||
                        `${row?.totalPrice || ""}`
                          .toLowerCase()
                          .includes(searchInput.toLowerCase().trim()) ||
                        `${row.currency || ""}`
                          .toLowerCase()
                          .includes(searchInput.toLowerCase().trim()) ||
                        `${Moment(row.createdAt).format(
                          "MMMM Do YYYY, h:mm:ss a"
                        ) || ""
                          }`
                          .toLowerCase()
                          .includes(searchInput.toLowerCase().trim()) ||
                        `${t(row?.typeOfPayment) || ""}`
                          .toLowerCase()
                          .includes(searchInput.toLowerCase().trim()) ||
                        `${t(row?.type + "_place") || ""}`
                          .toLowerCase()
                          .includes(searchInput.toLowerCase().trim())
                    )
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => {
                      return (
                        <TableRow
                          sx={{ cursor: "pointer" }}
                          hover
                          key={row.id + "demj"}
                          // onClick={() =>
                          //   navigate(`/admin/booked-lesson/${row.id}`)
                          // }
                        >
                          <TableCell align="center" >
                            {row.id}
                          </TableCell  >
                          <TableCell align="center" onClick={() =>
                            navigate(`/admin/booked-lesson/${row.id}`)
                          }>
                            {row.Teacher?.firstName +
                              " " +
                              row.Teacher?.lastName || t("username")}
                          </TableCell  >
                          <TableCell align="center" onClick={() =>
                            navigate(`/admin/booked-lesson/${row.id}`)
                          }>
                            {row.Student?.name || t("username")}
                          </TableCell >
                          <TableCell align="center">
                            {row?.period}
                          </TableCell>
                          <TableCell align="center">
                            {row?.totalPrice}
                          </TableCell>
                          <TableCell align="center">{row?.currency}</TableCell>
                          <TableCell align="center">
                            {Moment(row.createdAt).format(
                              "MMMM Do YYYY, h:mm:ss a"
                            )}
                          </TableCell>
                          <TableCell align="center">
                            {t(row?.typeOfPayment)}
                          </TableCell>
                          <TableCell align="center">
                            {t(row?.type + "_place")}
                          </TableCell>
                          <TableCell align="center">
                            {row?.teacherAccept ? t("confirmed") : t("pending")}
                          </TableCell>
                          <TableCell align="center">
                            {row?.studentAccept ? t("confirmed") : t("pending")}
                          </TableCell>
                          <TableCell align="center">
                            <div className="flex space-x-2 justify-between">
                              <Button
                                onClick={() => generatePDF(row)}
                                className="bg-blue-500 text-white p-2 rounded m-3"
                                variant="contained"
                              >
                                {t("Save as PDF")}
                              </Button>
                              <Button
                                onClick={() => printPDF(row)}
                                className="bg-green-500 text-white w-28 py-2 rounded"
                                variant="outlined"
                              >
                                {t("Print")}
                              </Button>
                            </div>
                          </TableCell>
                        </TableRow>
                      );
                    })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={data?.data.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      ) : (
        <Loading />
      )}
    </AdminLayout>
  );
}
