import React, {useState } from "react";
import { useTranslation } from "react-i18next";
import {
    Box,
    Button,
    Container,
    DialogActions,
    Paper,
    Typography,
} from "@mui/material";
import { useForm } from "react-hook-form";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import axios from "axios";
import ReactCodeInput from "react-code-input";

export default function VerifyOTP({ type, email }) {
    const { t } = useTranslation();
    const { closeSnackbar, enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();
    const lang = Cookies.get("i18next") || "en";
    const {
        handleSubmit,
    } = useForm({
        defaultValues: {
            email: email
        },
    });
    const [otp, setOtp] = useState("");

    async function onSubmit(data) {
        closeSnackbar();
        const formData = new FormData();
        formData.append("code", otp);
        for (const key in data) {
            formData.append(key, data[key]);
        }

        try {
            const res=await axios.post(
                `${process.env.REACT_APP_API_KEY}api/v1/otp/verifycode`,
                formData,
                {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                }
            );
            if (res.status === 200) {
                if(lang==="ar")
                {enqueueSnackbar(res.data.msg.arabic, { variant: "success", autoHideDuration: 8000 })}
                else
                {enqueueSnackbar(res.data.msg.english, { variant: "success", autoHideDuration: 8000 })}
                if(type==="advertisement"){
                    navigate(`./advertisement/${data.email}`)
                }else{
                    navigate(`./job/${data.email}`)

                }
            }

        } catch (err) {
            enqueueSnackbar(t("Something went wrong"), { variant: "error", autoHideDuration: 8000 });
        }
    }

    return (
        <>
            <Container sx={{ marginTop: "110px", display: "flex", textAlign: "center" }}>
                <Paper
                    sx={{
                        width: { md: "600px" },
                        padding: "30px 50px",
                        margin: "60px auto 400px",
                    }}
                >
                    <Typography
                        sx={{
                            marginTop: "20px",
                            fontSize: "14px",
                            textAlign: "center",
                            fontWeight: "700",
                            marginBottom: "20px",
                        }}
                    >
                        {t("Please enter the verification code")}
                    </Typography>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Box sx={{ marginBottom: "30px" }}>
                            <Box sx={{ direction: "rtl" }}>
                                <ReactCodeInput type="number" fields={4} alue={otp} onChange={setOtp} />
                            </Box>
                        </Box>
                        <DialogActions >
                            <Button
                                variant="contained"
                                type="submit"
                                sx={{ ml: "6px", mr: "6px", textTransform: "capitalize", width: "100%" }}
                            >
                                {t("Verify")}
                            </Button>
                        </DialogActions>
                    </form>
                </Paper>
            </Container>
        </>

    );
}
