import React from 'react';
import StudentLayout from '../../components/student/StudentLayout'
import instance from '../../axios/instance';
import { useSelector } from 'react-redux';
import { Button, Paper, styled, Table, TableBody, TableCell, TableContainer, TablePagination, TableRow } from '@mui/material';
import { t } from 'i18next';
import Loading from '../../components/Loading';
import Cookies from "js-cookie";
import { Link } from "react-router-dom";



const Root = styled('div')(({ theme }) => ({
    width: '100%',
    ...theme.typography.body2,
    color: theme.palette.text.secondary,
    '& > :not(style) ~ :not(style)': {
        marginTop: theme.spacing(2),
    },
}));

function StudentLecture() {
    const lang = Cookies.get("i18next") || "en";
    const columns = [
        { id: "image", label: t("Photo"), minWidth: 150 },
        { id: "name_course_ar", label: t("title"), minWidth: 150 },
        { id: "description_course_en", label: t("description"), minWidth: 150 },
        { id: "subject", label: t("subject"), minWidth: 150 },
        { id: "class", label: t("classes"), minWidth: 150 },
        { id: "semester", label: t("semester"), minWidth: 150 },
        { id: "studycurriculums", label: t("studycurriculums"), minWidth: 150 },
        { id: "linkLecture", label: t("Link Lecture"), minWidth: 150 },
        { id: "docs", label: t("Lecture documents"), minWidth: 150 },
        { id: "price", label: t("price"), minWidth: 150 },
    ];

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };
    const { student } = useSelector(state => state.student);
    const [data, setData] = React.useState(null);
    React.useEffect(() => {
        (async () => {
            const res = await instance(`student/getMyLectures/${student.id}`);
            setData(res.data.data);
            
        })();
    }, [student.id]);

     return (
        <StudentLayout>
            <Root>
                <Paper sx={{ width: "100%", padding: "20px" }}>
                    <TableContainer sx={{ maxHeight: 440 }}>
                        <Table stickyHeader aria-label="sticky table">
                            <TableRow>
                                {columns.map((column) => (
                                    <TableCell
                                        key={column.id}
                                        align={"center"}
                                        style={{ top: 57, minWidth: column.minWidth }}
                                    >
                                        {column.label}
                                    </TableCell>
                                ))}
                            </TableRow>
                            <TableBody>
                                {data
                                    ?.filter((row) => {
                                        return lang === "ar"
                                            ? row.lecture?.titleArabic && row.lecture?.descriptionArabic
                                            : row.lecture?.titleEnglish && row.lecture?.descriptionEnglish;
                                    })
                                    ?.map((row) => {
                                        return (
                                            <TableRow hover role="checkbox" key={row.id + "demj"}>
                                                <TableCell align="center"><img src={row.lecture?.image ? `${process.env.REACT_APP_API_KEY}images/${row.lecture?.image}` : "/logo.png"} alt={row.lecture?.image} width={"100px"} /></TableCell>
                                                <TableCell align="center">{lang === "ar" ?row.lecture?.titleArabic:row.lecture?.titleEnglish}</TableCell>
                                                <TableCell align="center">{lang === "ar" ?row.lecture?.descriptionArabic:row.lecture?.descriptionEnglish}</TableCell>

                                                <TableCell align="center">{lang === "ar" ?row.subject?.titleAR:row.subject?.titleEN}</TableCell>
                                                <TableCell align="center">{lang === "ar" ?row?.class?.titleAR:row?.class?.titleEN}</TableCell>
                                                <TableCell align="center">{t(row?.lecture?.semester)}</TableCell>
                                                <TableCell align="center">{lang === "ar" ?row.curriculums?.titleAR:row?.curriculums?.titleEN}</TableCell>
                                                {row.lecture?.linkLecture ? <TableCell align="center">
                            <Button
                              component={Link}
                              to={row.lecture?.linkLecture}
                              target="_blank" // يفتح الرابط في صفحة جديدة
                              rel="noopener noreferrer"
                            >
                              {t("link")}
                            </Button>
                          </TableCell> : <>
                        <TableCell align="center">
                        {t("No Link Lecture")}
                        </TableCell>
                          </>}
                        {row.lecture?.docs ? <TableCell align="center">
                            <Button
                              component={Link}
                              to={`${process.env.REACT_APP_API_KEY}images/${row.lecture?.docs}`}
                              target="_blank" // يفتح الرابط في صفحة جديدة
                              rel="noopener noreferrer"
                            >
                              {t("Lecture documents")}
                            </Button>
                          </TableCell> : <>
                          <TableCell align="center">{t("No Documents")}</TableCell>
                          </>}
                                                <TableCell align="center">{row.lecture?.price} {t(row.lecture?.currency)}</TableCell>

                                            </TableRow>
                                        );
                                    })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[10, 25, 100]}
                        component="div"
                        count={data?.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Paper>
            </Root>
        </StudentLayout>
    )
}

export default StudentLecture;