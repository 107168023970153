import { useQuery } from "react-query";

async function getLectures() {
    const response = await fetch(
      `${process.env.REACT_APP_API_KEY}api/v1/teacher/lectures/status/accept-lectures`
    );
    const data = await response.json();
    return data;
  }

  export const useLectures = () => {
    return useQuery("get-lectures", getLectures);
  };
