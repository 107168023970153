import { Box, Button, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material'
import Cookies from 'js-cookie';
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom';
export default function LectureSingleTeacher({ teacher }) {
    const {id}=useParams();
    const { t } = useTranslation()
    const navigate = useNavigate();
    const lang = Cookies.get("i18next") || "en";
    const handelLecture =(lectureId)=>{
        navigate(`/lecture/${id}/${lectureId}`)
    }
    
    return (
        <Paper sx={{ padding: "32px 24px", marginY: "30px" }}>
            <Typography sx={{ fontSize: "22px", marginBottom: "18px" }}>
                {t('Lectures')}
            </Typography>

            <Box sx={{ display: "flex", flexWrap: "wrap", gap: "16px", justifyContent: "center" }}>
                {teacher? (
                    <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell>{t("Photo")}</TableCell>
                          <TableCell>{t("title")}</TableCell>
                          <TableCell>{t("description")}</TableCell>
                          <TableCell>{t("price")}</TableCell>
                          <TableCell></TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {teacher.map((row) => (
                          <TableRow
                            key={row.name}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                          >
                            
                            <TableCell align="center">
                              <img src={row.image ? `${process.env.REACT_APP_API_KEY}images/${row.image}` : "/logo.png"} alt={row.image} width={"80px"} />
                            </TableCell>
                            <TableCell>{lang==="ar"?row.titleArabic:row.titleEnglish}</TableCell>
                            <TableCell>{lang==="ar"?row.descriptionArabic:row.descriptionEnglish}</TableCell>
                            <TableCell>{row.price} {t(row.currency)}</TableCell>
                            <TableCell>
                              <Button size="small" color="primary" onClick={()=>handelLecture(row.id)}>
                                {t("View more")}
                              </Button>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                ) : (
                    <Typography
                        sx={{
                            fontSize: "16px",
                            margin: "5px",
                            padding: "10px",
                            background: "#f8f9f9",
                            textAlign: "center",
                        }}
                    >
                        {t('There are no special Lectures.')}
                    </Typography>
                )}
            </Box>
        </Paper>
    )
}

