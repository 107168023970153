import { useQuery } from "react-query";

async function getPackages() {
    const response = await fetch(
      `${process.env.REACT_APP_API_KEY}api/v1/packages/get-packages`
    );
    const data = await response.json();
    return data;
  }

  export const usePackages = () => {
    return useQuery("get-packages", getPackages);
  };
