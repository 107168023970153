export default [ 
    {
        title: "SAR",
        titleAr: "ريال سعودي",
        titleEn: "Saudi Riyal",
        code: "sa"
    },
    {
        title: "AED",
        titleAr: "الدرهم الإماراتي",
        titleEn: "UAE Dirham",
        code: "ae"
    },
    {
        title: "KWD",
        titleAr: "الدينار الكويتي",
        titleEn: "Kuwaiti Dinar",
        code: "kw"
    },
    {
        title: "OMR",
        titleAr: "ريال عماني",
        titleEn: "Omani Rial",
        code: "om"
    },
    {
        title: "QAR",
        titleAr: "ريال قطري",
        titleEn: "Qatari Riyal",
        code: "qa"
    },
    {
        title: "BHD",
        titleAr: "دينار بحريني",
        titleEn: "Bahraini Dinar",
        code: "bh"
    },
    {
        title: "USD",
        titleAr: "دولار أمريكي",
        titleEn: "US Dollar",
        code: "us"
    },
    {
        title: "GBP",
        titleAr: "الجنيه الاسترليني",
        titleEn: "British Pound",
        code: "gb"
    },
    {
        title: "EUR",
        titleAr: "اليورو",
        titleEn: "Euro",
        code: "eu"
    },
    {
        title: "JOD",
        titleAr: "الدينار الأردني",
        titleEn: "Jordanian Dinar",
        code: "jo"
    },
    {
        title: "EGP",
        titleAr: "الجنيه المصري",
        titleEn: "Egyptian Pound",
        code: "eg"
    },
    {
        title: "ILS",
        titleAr: "الشيقل الفلسطيني",
        titleEn: "Israeli Shekel",
        code: "il"
    },
    {
        title: "INR",
        titleAr: "الروبية الهندية",
        titleEn: "Indian Rupee",
        code: "in"
    },
    {
        title: "JPY",
        titleAr: "الين الياباني",
        titleEn: "Japanese Yen",
        code: "jp"
    },
    {
        title: "CNY",
        titleAr: "اليوان الصيني",
        titleEn: "Chinese Yuan",
        code: "cn"
    },
    {
        title: "AUD",
        titleAr: "الدولار الأسترالي",
        titleEn: "Australian Dollar",
        code: "au"
    },
    {
        title: "CAD",
        titleAr: "الدولار الكندي",
        titleEn: "Canadian Dollar",
        code: "ca"
    },
    {
        title: "CHF",
        titleAr: "الفرنك السويسري",
        titleEn: "Swiss Franc",
        code: "ch"
    },
    {
        title: "SEK",
        titleAr: "الكرونا السويدية",
        titleEn: "Swedish Krona",
        code: "se"
    },
    {
        title: "NOK",
        titleAr: "الكرونا النرويجية",
        titleEn: "Norwegian Krone",
        code: "no"
    },
    {
        title: "DKK",
        titleAr: "الكرون الدنماركي",
        titleEn: "Danish Krone",
        code: "dk"
    },
    {
        title: "ZAR",
        titleAr: "الراند الجنوب أفريقي",
        titleEn: "South African Rand",
        code: "za"
    },
    {
        title: "RUB",
        titleAr: "الروبل الروسي",
        titleEn: "Russian Ruble",
        code: "ru"
    },
    {
        title: "TRY",
        titleAr: "الليرة التركية",
        titleEn: "Turkish Lira",
        code: "tr"
    },
    {
        title: "MXN",
        titleAr: "البيزو المكسيكي",
        titleEn: "Mexican Peso",
        code: "mx"
    },
    {
        title: "BRL",
        titleAr: "الريال البرازيلي",
        titleEn: "Brazilian Real",
        code: "br"
    },
    {
        title: "ARS",
        titleAr: "البيزو الأرجنتيني",
        titleEn: "Argentine Peso",
        code: "ar"
    },
    {
        title: "IDR",
        titleAr: "الروبية الإندونيسية",
        titleEn: "Indonesian Rupiah",
        code: "id"
    },
    {
        title: "KRW",
        titleAr: "الوون الكوري الجنوبي",
        titleEn: "South Korean Won",
        code: "kr"
    },
    {
        title: "THB",
        titleAr: "البات التايلاندي",
        titleEn: "Thai Baht",
        code: "th"
    },
    {
        title: "MYR",
        titleAr: "الرينغيت الماليزي",
        titleEn: "Malaysian Ringgit",
        code: "my"
    },
    {
        title: "SGD",
        titleAr: "الدولار السنغافوري",
        titleEn: "Singapore Dollar",
        code: "sg"
    },
    {
        title: "NZD",
        titleAr: "الدولار النيوزيلندي",
        titleEn: "New Zealand Dollar",
        code: "nz"
    },
    {
        title: "HKD",
        titleAr: "الدولار هونج كونج",
        titleEn: "Hong Kong Dollar",
        code: "hk"
    },
    {
        title: "PKR",
        titleAr: "الروبية الباكستانية",
        titleEn: "Pakistani Rupee",
        code: "pk"
    },
    {
        title: "BDT",
        titleAr: "التاكا البنغلاديشي",
        titleEn: "Bangladeshi Taka",
        code: "bd"
    },
    {
        title: "NGN",
        titleAr: "النيرة النيجيرية",
        titleEn: "Nigerian Naira",
        code: "ng"
    },
    {
        title: "KES",
        titleAr: "الشلن الكيني",
        titleEn: "Kenyan Shilling",
        code: "ke"
    },
    {
        title: "UGX",
        titleAr: "الشلن الأوغندي",
        titleEn: "Ugandan Shilling",
        code: "ug"
    },
    {
        title: "GHS",
        titleAr: "السيدي الغاني",
        titleEn: "Ghanaian Cedi",
        code: "gh"
    },
    {
        title: "PHP",
        titleAr: "البيزو الفلبيني",
        titleEn: "Philippine Peso",
        code: "ph"
    },
    {
        title: "VND",
        titleAr: "الدونج الفيتنامي",
        titleEn: "Vietnamese Dong",
        code: "vn"
    },
    {
        title: "IRR",
        titleAr: "الريال الإيراني",
        titleEn: "Iranian Rial",
        code: "ir"
    },
    {
        title: "IQD",
        titleAr: "الدينار العراقي",
        titleEn: "Iraqi Dinar",
        code: "iq"
    },
    {
        title: "SYP",
        titleAr: "الليرة السورية",
        titleEn: "Syrian Pound",
        code: "sy"
    },
    {
        title: "LBP",
        titleAr: "الليرة اللبنانية",
        titleEn: "Lebanese Pound",
        code: "lb"
    },
    {
        title: "YER",
        titleAr: "الريال اليمني",
        titleEn: "Yemeni Rial",
        code: "ye"
    },
    {
        title: "AFN",
        titleAr: "الأفغاني الأفغاني",
        titleEn: "Afghan Afghani",
        code: "af"
    },
    {
        title: "NPR",
        titleAr: "الروبية النيبالية",
        titleEn: "Nepalese Rupee",
        code: "np"
    },
    {
        title: "MMK",
        titleAr: "الكيات الميانماري",
        titleEn: "Myanmar Kyat",
        code: "mm"
    },
    {
        title: "KHR",
        titleAr: "الرييل الكمبودي",
        titleEn: "Cambodian Riel",
        code: "kh"
    },
    {
        title: "LAK",
        titleAr: "الكيب اللاوسي",
        titleEn: "Lao Kip",
        code: "la"
    },
    {
        title: "MNT",
        titleAr: "التوغروغ المنغولي",
        titleEn: "Mongolian Tugrik",
        code: "mn"
    },
    {
        title: "UZS",
        titleAr: "السوم الأوزبكي",
        titleEn: "Uzbekistani Som",
        code: "uz"
    },
    {
        title: "KZT",
        titleAr: "التنغ الكازاخستاني",
        titleEn: "Kazakhstani Tenge",
        code: "kz"
    },
    {
        title: "TJS",
        titleAr: "السوموني الطاجيكي",
        titleEn: "Tajikistani Somoni",
        code: "tj"
    },
    {
        title: "TMT",
        titleAr: "المانات التركمانستاني",
        titleEn: "Turkmenistani Manat",
        code: "tm"
    },
    {
        title: "AZN",
        titleAr: "المانات الأذربيجاني",
        titleEn: "Azerbaijani Manat",
        code: "az"
    },
    {
        title: "GEL",
        titleAr: "اللاري الجورجي",
        titleEn: "Georgian Lari",
        code: "ge"
    },
    {
        title: "AMD",
        titleAr: "الدرام الأرمني",
        titleEn: "Armenian Dram",
        code: "am"
    },
    {
        title: "BYN",
        titleAr: "الروبل البيلاروسي",
        titleEn: "Belarusian Ruble",
        code: "by"
    },
    {
        title: "UAH",
        titleAr: "الهريفنيا الأوكرانية",
        titleEn: "Ukrainian Hryvnia",
        code: "ua"
    },
    {
        title: "MDL",
        titleAr: "الليو المولدوفي",
        titleEn: "Moldovan Leu",
        code: "md"
    },
    {
        title: "RSD",
        titleAr: "الدينار الصربي",
        titleEn: "Serbian Dinar",
        code: "rs"
    },
    {
        title: "BGN",
        titleAr: "الليف البلغاري",
        titleEn: "Bulgarian Lev",
        code: "bg"
    },
    {
        title: "HRK",
        titleAr: "الكونا الكرواتية",
        titleEn: "Croatian Kuna",
        code: "hr"
    },
    {
        title: "CZK",
        titleAr: "الكرونة التشيكية",
        titleEn: "Czech Koruna",
        code: "cz"
    },
    {
        title: "PLN",
        titleAr: "الزلوتي البولندي",
        titleEn: "Polish Zloty",
        code: "pl"
    },
    {
        title: "HUF",
        titleAr: "الفورنت المجري",
        titleEn: "Hungarian Forint",
        code: "hu"
    },
    {
        title: "RON",
        titleAr: "الليو الروماني",
        titleEn: "Romanian Leu",
        code: "ro"
    },
    {
        title: "ISK",
        titleAr: "الكرونا الأيسلندية",
        titleEn: "Icelandic Króna",
        code: "is"
    },
    {
        title: "MAD",
        titleAr: "الدرهم المغربي",
        titleEn: "Moroccan Dirham",
        code: "ma"
    },
    {
        title: "TND",
        titleAr: "الدينار التونسي",
        titleEn: "Tunisian Dinar",
        code: "tn"
    },
    {
        title: "DZD",
        titleAr: "الدينار الجزائري",
        titleEn: "Algerian Dinar",
        code: "dz"
    },
    {
        title: "LYD",
        titleAr: "الدينار الليبي",
        titleEn: "Libyan Dinar",
        code: "ly"
    },
    {
        title: "XOF",
        titleAr: "فرنك غرب أفريقيا",
        titleEn: "West African CFA Franc",
        code: "bj" // Benin is used as a representative code
    },
    {
        title: "XAF",
        titleAr: "فرنك وسط أفريقيا",
        titleEn: "Central African CFA Franc",
        code: "cm" // Cameroon is used as a representative code
    },
    {
        title: "XCD",
        titleAr: "دولار شرق الكاريبي",
        titleEn: "East Caribbean Dollar",
        code: "ag" // Antigua and Barbuda is used as a representative code
    },
    {
        title: "ANG",
        titleAr: "الفلورن الأنتيليني",
        titleEn: "Netherlands Antillean Guilder",
        code: "cw" // Curaçao is used as a representative code
    },
    {
        title: "AWG",
        titleAr: "الفلورن الأروبي",
        titleEn: "Aruban Florin",
        code: "aw"
    },
    {
        title: "BSD",
        titleAr: "الدولار البهامي",
        titleEn: "Bahamian Dollar",
        code: "bs"
    },
    {
        title: "BBD",
        titleAr: "الدولار البربادوسي",
        titleEn: "Barbadian Dollar",
        code: "bb"
    },
    {
        title: "BZD",
        titleAr: "الدولار البليزي",
        titleEn: "Belize Dollar",
        code: "bz"
    },
    {
        title: "BND",
        titleAr: "الدولار البروناي",
        titleEn: "Brunei Dollar",
        code: "bn"
    },
    {
        title: "KYD",
        titleAr: "الدولار الكايماني",
        titleEn: "Cayman Islands Dollar",
        code: "ky"
    },
    {
        title: "CLP",
        titleAr: "البيزو التشيلي",
        titleEn: "Chilean Peso",
        code: "cl"
    },
    {
        title: "COP",
        titleAr: "البيزو الكولومبي",
        titleEn: "Colombian Peso",
        code: "co"
    },
    {
        title: "CRC",
        titleAr: "الكولون الكوستاريكي",
        titleEn: "Costa Rican Colón",
        code: "cr"
    },
    {
        title: "CUP",
        titleAr: "البيزو الكوبي",
        titleEn: "Cuban Peso",
        code: "cu"
    },
    {
        title: "DOP",
        titleAr: "البيزو الدومينيكي",
        titleEn: "Dominican Peso",
        code: "do"
    },
    {
        title: "GTQ",
        titleAr: "الكويتزال الغواتيمالي",
        titleEn: "Guatemalan Quetzal",
        code: "gt"
    },
    {
        title: "HNL",
        titleAr: "اللمبيرا الهندوراسي",
        titleEn: "Honduran Lempira",
        code: "hn"
    },
    {
        title: "JMD",
        titleAr: "الدولار الجامايكي",
        titleEn: "Jamaican Dollar",
        code: "jm"
    },
    {
        title: "NIO",
        titleAr: "الكوردوبا النيكاراغوي",
        titleEn: "Nicaraguan Córdoba",
        code: "ni"
    },
    {
        title: "PAB",
        titleAr: "البالبوا البنمي",
        titleEn: "Panamanian Balboa",
        code: "pa"
    },
    {
        title: "PYG",
        titleAr: "الغواراني الباراغواي",
        titleEn: "Paraguayan Guaraní",
        code: "py"
    },
    {
        title: "PEN",
        titleAr: "السول البيروفي",
        titleEn: "Peruvian Sol",
        code: "pe"
    },
    {
        title: "SRD",
        titleAr: "الدولار السورينامي",
        titleEn: "Surinamese Dollar",
        code: "sr"
    },
    {
        title: "TTD",
        titleAr: "الدولار الترينيدادي",
        titleEn: "Trinidad and Tobago Dollar",
        code: "tt"
    },
    {
        title: "UYU",
        titleAr: "البيزو الأوروغواي",
        titleEn: "Uruguayan Peso",
        code: "uy"
    },
    {
        title: "VES",
        titleAr: "البوليفار الفنزويلي",
        titleEn: "Venezuelan Bolívar",
        code: "ve"
    },
]