import {
    Box,
    Button,
    Container,
    FormControl,
    FormControlLabel,
    InputLabel,
    MenuItem,
    Paper,
    Radio,
    RadioGroup,
    Select,
    TextField,
    Typography,
  } from "@mui/material";
  import React, { useEffect, useState } from "react";
  import { useTranslation } from "react-i18next";
  import { useForm, Controller } from "react-hook-form";
  import LocalOfferIcon from "@mui/icons-material/LocalOffer";
  import PaidIcon from "@mui/icons-material/Paid";
  import { useNavigate, useParams } from "react-router-dom";
  import { useSelector } from "react-redux";
  import Cookies from "js-cookie";
  import { useSnackbar } from "notistack";
import Navbar from "../../components/Navbar";
import { useRequestLessonById } from "../../hooks/useRequestLessionById";
import Loading from "../../components/Loading";
  
  export default function PayLesson() {
    const lang = Cookies.get("i18next") || "en";
    const { id } = useParams();
    const { currency } = useSelector((state) => state.currency);
    const { conversionRate } = useSelector((state) => state.conversionRate);
    const { t } = useTranslation();
    
    const [load, setLoad] = useState(false);
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  
    const { student } = useSelector((state) => state.student);
    const navigate = useNavigate();
    
  
       const { data, isLoading } = useRequestLessonById(id)
       const [lession, setlession] = useState([])
   
   
       useEffect(() => {
           if (data) {
               setlession(data?.data)
               console.log(data?.data);
           }
       }, [data])
  
       const {
        register,
        control,
        formState: { errors },
        handleSubmit,
        watch,
      } = useForm({
        defaultValues: {
          hours: "1",
          typeLesson: "online",
          date: "",
          time: "",
          typeofbook: "",
        },
      });
    
      async function onSubmit(data) {
        setLoad(true);
        try {
          closeSnackbar();
          const response = await fetch(
            `${process.env.REACT_APP_API_KEY}api/v1/payment/booking`,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                StudentId: lession?.studentId,
                TeacherId: lession?.teacher.id,
                price: lession?.price,
                currency: "OMR",
                typeOfPayment: data.typeofbook,
                period: lession?.period,
                date: lession?.date,
                type: lession?.type,
                title: "Lesson Booking", 
              }),
            }
          );
          setLoad(false);
          const resData = await response.json();
          if (response.status !== 200 && response.status !== 201) {
            enqueueSnackbar(t(resData.message), {
              variant: "error",
              autoHideDuration: 8000,
            });
            throw new Error("failed occurred");
          }
          if (data.typeofbook === "wallet") {
            navigate("/student/allLessons");
            enqueueSnackbar(
              lang === "ar" ? resData.msg.arabic : resData.msg.english,
              { variant: "success", autoHideDuration: 8000 }
            );
          } else {
            window.location.replace(resData.data);
          }
        } catch (err) {
          console.log(err);
        }
      }
    return (
      <Navbar>
        <Container sx={{ marginY: "100px" }}>
          <Paper sx={{ padding: "30px 20px" }}>
            <Typography
              sx={{ fontSize: "24px", fontWeight: "600", marginBottom: "24px" ,textAlign: "center" }}
            >
              {t("bookDetails")}
            </Typography>
                            {!isLoading?
                            <>
                                    <Box sx={{ marginBottom: "12px",background:"#f2f3f4",padding:"1rem" }}>
                                        <Box sx={{ marginBottom: "6px" }}>
                                            <Typography variant="h6" className='flex justify-end items-center' >
                                                {lession.teacher?.firstName} {lession.teacher?.lastName} 
                                                <img src={lession.teacher?.image?`${process.env.REACT_APP_API_KEY}images/${lession.teacher?.image}` : "/logo.png"} alt={lession.teacher?.image}  style={{borderRadius:"50%",height:"80px",width:"80px",marginRight:"1rem"}}/>
                                            </Typography>
                                            <Box className="flex justify-around">
                                            <Typography  gutterBottom className='border px-3 py-2 rounded w-52 text-3xl text-center'>
                                                {t("Time")}{" : "} {new Date(lession?.date).toLocaleTimeString()}
                                            </Typography>
                                            <Typography  gutterBottom className='border px-3 py-2 rounded w-52 text-3xl text-center'>
                                                {t("day")}{" : "} {new Date(lession?.date).toLocaleDateString()}
                                            </Typography>
                                            </Box>
                                            <Box className="flex justify-around">
                                            <Typography  gutterBottom className='border px-3 py-2 rounded w-52 text-3xl text-center'>
                                                {t("status")}{" : "} {
                                                    lession?.status === 'pending'? <span className='text-[#f4d03f]'>{t('pending') }</span>: (lession?.status === 'approved'?<span className='text-[#2ecc71]'>{t('approved') }</span>:<span className='text-[#e74c3c]'>{t('canceled') }</span>)
                                                } 
                                            </Typography>
                                            <Typography  gutterBottom className='border px-3 py-2 rounded w-52 text-3xl text-center' >
                                                {t("lessonType")}{" : "} {t(lession?.type)}
                                            </Typography>
                                            </Box>
                                            <Box className="flex justify-around">
                                            <Typography  gutterBottom className='border px-3 py-2 rounded w-52 text-3xl text-center'>
                                                {t("Session number")}{" : "} {lession?.period} 
                                            </Typography>
                                            <Typography  gutterBottom className='border px-3 py-2 rounded w-52 text-3xl text-center'>
                                                {t("price")}{" : "} {lession?.price } {" "} {t(lession?.currency)} 
                                            </Typography>
                                            </Box>
                                        </Box>
                                    </Box> 
                                              
                            </>:<> <Loading /> </>}
            <form onSubmit={handleSubmit(onSubmit)}>
              {/*  */}

              <Box sx={{ marginBottom: "26px" }}>
                <Controller
                  name="typeofbook"
                  control={control}
                  render={({ field }) => (
                    <RadioGroup {...field}>
                      <FormControlLabel
                        value="wallet"
                        control={<Radio size="2px" />}
                        label={`${t("credit")} (${t("wallet")})`}
                      />
                      <FormControlLabel
                        value="thawani"
                        control={<Radio size="2px" />}
                        label={t("gateway")}
                      />
                    </RadioGroup>
                  )}
                  {...register("typeofbook", {
                    required: "typeofbook Address is required",
                  })}
                />
                {errors.typeofbook?.type === "required" && (
                  <Typography
                    color="error"
                    role="alert"
                    sx={{ fontSize: "13px", marginTop: "6px" }}
                  >
                    {t("required")}
                  </Typography>
                )}
              </Box>
              {!load ? (
                <Button fullWidth type="submit" variant="contained">
                  {t("next")}
                </Button>
              ) : (
                <Button fullWidth variant="contained">
                  {t("next")}...
                </Button>
              )}
            </form> 
          </Paper>
        </Container>
      </Navbar>
    );
  }
  