import React from "react";
import DownloadApp from "../../components/client/home/DownloadApp";
import Footer from "../../components/client/home/Footer";
import HomeBanner from "../../components/client/home/HomeBanner";
import HomeQuestions from "../../components/client/home/HomeQuestions";
import HomeWorks from "../../components/client/home/HomeWorks";
import LinksFooter from "../../components/client/home/LinksFooter";
import { useSelector } from "react-redux";
import MapBrowsing from "../../components/client/home/MapBrowsing";
import Navbar from "../../components/Navbar";
import JOBSection from "./JOBSection";
import AdvertisementSection from "./AdvertisementSection";
import DiscountSection from "./DiscountSection";
import RescourceSection from "./RescourceSection";
import ExameSection from "./ExameSection";

export default function Home() {
  const { student } = useSelector((state) => state.student);
  return (
    <Navbar>
      {student && student.lat && student.long && <MapBrowsing />}
      <HomeBanner />
      {/* jobs */}
      <JOBSection />
      {/* advertisement */}
      <AdvertisementSection />
      {/* discounts */}
      <DiscountSection />
      {/* resource */}
      <RescourceSection />
      {/* exames */}
      <ExameSection />
      <HomeWorks />
      <HomeQuestions />
      <DownloadApp />
      <LinksFooter />
      <Footer />
    </Navbar>
  );
}
