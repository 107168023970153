import { useQuery } from "react-query";

async function getDistcounts() {
    const response = await fetch(
      `${process.env.REACT_APP_API_KEY}api/v1/teacher/discounts/status/accept-discounts`
    );
    const data = await response.json();
    return data;
  }

  export const useDistcounts = () => {
    return useQuery("get-discounts", getDistcounts);
  };
