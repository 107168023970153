import React, { useEffect, useState } from 'react';
import { Alert, Button, FormControl, FormLabel, RadioGroup, FormControlLabel, Radio, Grid, Card, CardContent, Typography } from "@mui/material";
import { TextareaAutosize } from '@mui/base/TextareaAutosize';
import { useNavigate } from 'react-router-dom';
import Cookies from "js-cookie";
import { useTranslation } from 'react-i18next';
import AdminLayout from '../../components/admin/AdminLayout';
import Loading from '../../components/Loading';
import { useAdminComplaint } from '../../hooks/useAdminComplaint';

function CustomerComplaints() {
  const [complaints,setComplaints] = useState([])
  const navigate = useNavigate();
  const lang = Cookies.get("i18next") || "en";
  const { t } = useTranslation();
  const {data,isData}=useAdminComplaint()

   useEffect(() => {
      if (!isData) {
        setComplaints(data?.data);
      }
    }, [data]);

  const handelReply=(messageId,studentId)=>{
    navigate(`/admin/reply-complaint/${messageId}/${studentId}`)
  }
  return (
    <AdminLayout>
    {!complaints?<><Loading /></>:<>
    <Grid container spacing={2} sx={{ padding: 2 }}>
      {complaints.map((item, index) => (
        <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
          <Card sx={{ padding: 2, textAlign: "center", boxShadow: 3 }}>
            <CardContent>
            <Typography variant="h6" component="div" className='flex justify-center items-center'>
                <img src={item.from_user?.image ? `${process.env.REACT_APP_API_KEY}images/${item.from_user?.image}` : "/logo.png"} alt="" width={"100px"} />
                {item.from_user?.name}
              </Typography>
              <Typography variant="h6" component="div" className='text-red-600'>
                {item.title}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {item.text}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {!item.isReply?<>
                <Button onClick={()=>handelReply(item.id,item.from_user?.id)}>{t("Reply to the complaint")}</Button>
                </>:<>
                <Typography variant="body2" color="text.secondary">
                  <hr color="text.secondary" className='my-3'/>
                  {item.reply}
                </Typography>
                </>}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>

      </>}     

    </AdminLayout>
  );
}

export default CustomerComplaints;
