import { useQuery } from "react-query";

async function getTests() {
    const response = await fetch(
      `${process.env.REACT_APP_API_KEY}api/v1/teacher/exams/status/accept-exams`
    );
    const data = await response.json();
    return data;
  }

  export const useTests = () => {
    return useQuery("get-tests", getTests);
  };
