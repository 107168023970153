import { Box, Typography, styled } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const Wrapper = styled(Box)({
  backgroundPosition: "center",
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
  color: "white",
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-around",
  alignItems: "center",
  textAlign: "center",
  flexWrap: "wrap", // لجعل العناصر تتكيف مع الشاشة الصغيرة
});

export default function AdvertisementSection() {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <Box sx={{ maxWidth: "500px", textAlign: "center" , marginBottom:{md:6,xs:10},
            marginTop:{md:5,xs:7}}}>
        <Typography
          sx={{
            fontSize: { md: "32px", xs: "22px" },
            fontWeight: "bold",
            color: "#3B82F6ED"
          }}
        >
          {t("Education Services Advertisements")}
        </Typography>
        <Typography
          sx={{
            fontSize: { lg: "18px", md: "18px", xs: "14px" },
            fontWeight: "400",
            margin: "1.5rem 0",
            paddingX: "10px",
            color: "#000000",
          }}
        >
          {t(
            "The Moalemy platform provides you with the opportunity to post and view advertisements for educational services, such as school offices, private lessons, school supplies, and more. If you offer educational services or have a school office, you can now easily post your advertisement. Those interested in searching for these services can browse the available advertisements and choose the one that best suits them."
          )}
        </Typography>
        <Box
          sx={{
            display: "flex",
            justifyContent:"center",
            gap: 2,
            marginTop: "2rem",
          }}
        >
          <Link to="/advertisement">
            <Box
              sx={{
                minWidth: "120px",
                border: "1px solid",
                borderRadius: "5px",
                padding: "8px 12px",
                color: "#3B82F6ED",
                backgroundColor: "#FFFFFF",
                textAlign: "center",
              }}
            >
              {t("Advertisements")}
            </Box>
          </Link>
          <Link to="/Guest">
            <Box
              sx={{
                minWidth: "120px",
                border: "1px solid",
                borderRadius: "5px",
                padding: "8px 12px",
                color: "#FFF",
                backgroundColor: "#3B82F6ED",
                textAlign: "center",
              }}
            >
              {t("Add Ad Here")}
            </Box>
          </Link>
        </Box>
      </Box>

      {/* إخفاء الصورة عند الشاشات الصغيرة */}
      <Box
        sx={{
          borderRadius: "12px",
          width: "600px",
          display: { xs: "none", md: "block" },
        }}
      >
        <img src="https://server.moalime.com/drive/2.png" alt="Ad" width="100%" height="400px"/>
      </Box>
    </Wrapper>
  );
}
