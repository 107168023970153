import React, { useState, useEffect } from "react";
import { Box, Button, Paper, Tab } from "@mui/material";
import StudentLayout from "../../components/student/StudentLayout";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { useTranslation } from "react-i18next";
import BookedLesson from "../../components/student/BookedLesson";
import { useAllLessons } from "../../hooks/useAllLessons";
import { useSelector } from "react-redux";
import VideoCallIcon from "@mui/icons-material/VideoCall";
import Loading from "../../components/Loading";

const AllLessons = () => {
  const { t } = useTranslation();
  const [value, setValue] = useState("1");
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const { student } = useSelector((state) => state.student);

  const { data: allLessons, isLoading: allLessonsLoading } = useAllLessons(student?.id);

  return (
    <StudentLayout>
      <Paper sx={{ padding: "20px" }}>
        <Button
          href="http://meet.google.com/new"
          target="_blank"
          variant="contained"
          color="success"
          size="medium"
          endIcon={<VideoCallIcon sx={{ marginRight: "16px", fontSize: "large" }} />}
        >
          {t("start_lesson_meet")}
        </Button>
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList onChange={handleChange} aria-label="lab API tabs example">
              <Tab label={t("alllessons")} value="1" />
            </TabList>
          </Box>
          <TabPanel value="1">
            {!allLessonsLoading && allLessons?.data?.length > 0 ?
              <>
              {
                allLessons.data.map((lesson, index) => (
                    <BookedLesson
                      key={`allLesson-${lesson.id}`}
                      image={lesson.Teacher?.image}
                      name={`${lesson?.Teacher?.firstName} ${lesson?.Teacher?.lastName}`}
                      parentTeacher={lesson?.Teacher}
                      parentStudent={student}
                      date={lesson?.date}
                      type={lesson?.type}
                      period={lesson?.period}
                      isStudent={true}
                      studentAccept={lesson?.studentAccept}
                      teacherAccept={lesson?.teacherAccept}
                      sessionId={lesson?.id}
                      startedAt={lesson?.startedAt}
                      endedAt={lesson?.endedAt}
                    />
                  ))
              }
              </>:<>
              <Loading />
              </>
            }
          </TabPanel>
        
        </TabContext>
      </Paper>
    </StudentLayout>
  );
};

export default AllLessons;
