import * as React from "react";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Inventory2Icon from '@mui/icons-material/Inventory2';
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
// icons
import homeIcon from "../images/home.svg";
import loginIcon from "../images/login.svg";
import accountIcon from "../images/account.svg";
import packageIcon from "../images/package.svg";
import lectureIcon from "../images/lecture2.svg";
import searchIcon from "../images/searchTeacher.svg";
import notificationIcon from "../images/notification.svg";
import logoutIcon from "../images/logout.svg";
import profileIcon from "../images/profile.svg";

import { Link, useNavigate } from "react-router-dom";
import ChangeLanguage from "./reusableUi/ChangeLanguage";
import {
  Badge,
  Button,
  ListItemIcon,
  Menu,
  MenuItem,
  Stack,
  styled,
} from "@mui/material";
import SelectCurrency from "./reusableUi/SelectCurrency";
import logoImage from "../images/logo.png";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { logoutTeacher } from "../redux/teacherSlice";
import { logoutParent } from "../redux/parentSlice";
import cookies from "js-cookie";
import { studentLogout } from "../redux/studentSlice";
import PersonIcon from "@mui/icons-material/Person";
import EmailIcon from "@mui/icons-material/Email";
import CastForEducationIcon from "@mui/icons-material/CastForEducation";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import LogoutIcon from "@mui/icons-material/Logout";
import NotificationsIcon from "@mui/icons-material/Notifications";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import Groups2Icon from "@mui/icons-material/Groups2";
import { collection, onSnapshot, query, where } from "firebase/firestore";
import MenuBookIcon from '@mui/icons-material/MenuBook';
import { db } from "../firebase";
import { useSocialMedia } from "../hooks/useSocialMedia";
import { AddCircle, Discount, LibraryBooks, QuestionAnswer, QuestionMark, Quiz, Settings } from "@mui/icons-material";
import RequestPageIcon from '@mui/icons-material/RequestPage';
import GradingIcon from '@mui/icons-material/Grading';
const drawerWidth = 240;

const ImageLogo = styled("img")({
  width: "110px",
  // height: "50px",
});

const ImageCall = styled("img")({
  width: "20px",
  height: "20px",
});

function Navbar(props) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const { teacher } = useSelector((state) => state.teacher);
  const { parent } = useSelector((state) => state.parent);
  const lang = cookies.get("i18next") || "en";
  const { student } = useSelector((state) => state.student);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  function handleTeacherLogout() {
    dispatch(logoutTeacher());
    navigate("/login");
  }

  function handleStudentLogout() {
    dispatch(studentLogout());
    navigate("/login");
  }

  const [notSeen, setNotSeen] = React.useState(0);
  React.useEffect(() => {
    if (teacher) {
      const q = query(
        collection(db, "Notifications"),
        where("TeacherId", "==", `${teacher.id}`)
      );
      const unsubscribe = onSnapshot(q, (querySnapshot) => {
        let conv = [];
        querySnapshot.forEach((doc) => {
          conv.push({ ...doc.data(), id: doc.id });
        });
        setNotSeen(conv.filter((not) => not.seen === false).length);
      });
      return () => unsubscribe();
    }
  }, [teacher]);

  const container =
    window !== undefined ? () => window().document.body : undefined;

  /** teacher profile links */
  const teacherProfile = [
    {
      title: t("profile"),
      link: "/about",
      icon: <ManageAccountsIcon fontSize="small" />,
    },
    {
      title: t("lessons"),
      link: "/lessons",
      icon: <CastForEducationIcon fontSize="small" />,
    },
    {
      title: t("Lesson booking requests"),
      link: "/request-lesson",
      icon: <GradingIcon fontSize="small" />,
    },
    {
      title: t("my_students"),
      link: "/students",
      icon: <Groups2Icon fontSize="small" />,
    },
    {
      title: t("myPackage"),
      link: "/package",
      icon: <Inventory2Icon fontSize="small" />,
    },
    {
      title: t("add_package"),
      link: "/add-package",
      icon: <AddCircle fontSize="small" />,
    },
    {
      title: t("Resource"),
      link: "/resources",
      icon: <MenuBookIcon fontSize="small" />,
    },
    {
      title: t("addResource"),
      link: "/AddResources",
      icon: <AddCircle fontSize="small" />,
    },
    {
      title: t("lectures"),
      link: "/lectures",
      icon: <LibraryBooks fontSize="small" />,
    },
    {
      title: t("Questions"),
      link: "/questions",
      icon: <QuestionMark fontSize="small" />,
    },
    {
      title: t("Answer"),
      link: "/answers",
      icon: <QuestionAnswer fontSize="small" />,
    },
    {
      title: t("Exams"),
      link: "/exams",
      icon: <Quiz fontSize="small" />,
    },
    {
      title: t("Discounts"),
      link: "/discounts",
      icon: <Discount fontSize="small" />,
    },
    {
      title: t("My bills"),
      link: "/mybills",
      icon: <RequestPageIcon fontSize="small" />,
    },
    {
      title: t("Setting"),
      link: "/setting",
      icon: <Settings fontSize="small" />,
    },
    {
      title: t("messages"),
      link: "/messages",
      icon: <EmailIcon fontSize="small" />,
    },
    {
      title: t("credit"),
      link: "/credit",
      icon: <CreditCardIcon fontSize="small" />,
    },
  ];

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const drawer = (
    <Box
      onClick={handleDrawerToggle}
      sx={{ textAlign: "start", height: "200vh", backgroundColor: "#3B82F6ED" }}
    >
      <Typography variant="h6" sx={{ my: 2, display: "flex", justifyContent: "center" }}>
        <ImageLogo src={logoImage} />
      </Typography>
      <Divider />
      <List>
        <Box sx={{
          textAlign: "start",
          color: "#fff"
        }}>
          <Link to="/">
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                padding: "0px 10px 10px",
                fontSize: "15px"
              }}
            >
              <Box sx={{
                      minWidth: "30px",
                    }}><img src={homeIcon} alt={homeIcon} width={20} /></Box>
              <Box sx={{
                marginLeft: "3px",
                marginTop:"3px"
              }}>{t("Home")}</Box>
            </Box>
          </Link>
          <Link to="/login">
          <Box
              sx={{
                display: "flex",
                alignItems: "center",
                padding: "0px 10px 10px",
                fontSize: "15px"
              }}
            >
              <Box sx={{
                      minWidth: "30px",
                    }}><img src={loginIcon} alt={loginIcon} width={20} /></Box>
              <Box sx={{
                marginLeft: "3px",
                marginTop:"3px"
              }}>{t("login")}</Box>
            </Box>
          </Link>
          <Link to="/teacherRegister/step1">
          <Box
              sx={{
                display: "flex",
                alignItems: "center",
                padding: "0px 10px 10px",
                fontSize: "15px"
              }}
            >
              <Box sx={{
                      minWidth: "30px",
                    }}><img src={accountIcon} alt={accountIcon} width={20} /></Box>
              <Box sx={{
                marginLeft: "3px",
                marginTop:"3px"
              }}>{t("becometeacher")}</Box>
            </Box>
          </Link>
          <Link to="/landing">
          <Box
              sx={{
                display: "flex",
                alignItems: "center",
                padding: "0px 10px 10px",
                fontSize: "15px"
              }}
            >
              <Box sx={{
                      minWidth: "30px",
                    }}><img src={searchIcon} alt={searchIcon} width={20} /></Box>
              <Box sx={{
                marginLeft: "3px",
                marginTop:"3px"
              }}>{t("search_for_teachers")}</Box>
            </Box>
          </Link>
          <Link to="/packages">
          <Box
              sx={{
                display: "flex",
                alignItems: "center",
                padding: "0px 10px 10px",
                fontSize: "15px"
              }}
            >
              <Box sx={{
                      minWidth: "30px",
                    }}><img src={packageIcon} alt={packageIcon} width={20} /></Box>
              <Box sx={{
                marginLeft: "3px",
                marginTop:"3px"
              }}>{t("packages")}</Box>
            </Box>
          </Link>
          <Link to="/lectures">
          <Box
              sx={{
                display: "flex",
                alignItems: "center",
                padding: "0px 10px 10px",
                fontSize: "15px"
              }}
            >
              <Box sx={{
                      minWidth: "30px",
                    }}><img src={lectureIcon} alt={lectureIcon} width={20} /></Box>
              <Box sx={{
                marginLeft: "3px",
                marginTop:"3px"
              }}>{t("Lectures")}</Box>
            </Box>
          </Link>
        </Box>
        <SelectCurrency />
        {teacher && (
          <>
            <Link to="/teacher/notifications">
              <ListItem
                sx={{
                  color: "white",
                  display: "flex",
                  alignItems: "center",
                  padding: "10px 10px 10px",
                  fontSize: "15px"
                }}
              >
              <Box sx={{
                      minWidth: "30px",
                    }}><img src={notificationIcon} alt={notificationIcon} width={20} /></Box>
              <Box sx={{
                marginLeft: "3px",
                marginTop:"3px"
              }}>{t("notifications")}</Box>
              </ListItem>
            </Link>
            {teacherProfile.map((item, index) => {
              return (
                <Link to={`/teacher${item.link}`} key={index + "a1"}>
                  <ListItem
                    sx={{
                      color: "white",
                      display: "flex",
                      alignItems: "center",
                      padding: "10px 10px 10px",
                      fontSize: "15px"
                    }}
                  >
                    <ListItemIcon sx={{
                      minWidth: "30px",
                    }}> {React.cloneElement(item.icon, { style: { color: "white" } })}</ListItemIcon>
                    <Box >{item.title}</Box>
                  </ListItem>
                </Link>
              );
            })}

            <Link to="/" onClick={handleTeacherLogout}>
              <ListItem
                sx={{
                  color: "white",
                  display: "flex",
                  alignItems: "center",
                  padding: "10px 10px 10px",
                  fontSize: "15px"
                }}
              >
              <Box sx={{
                      minWidth: "30px",
                    }}><img src={logoutIcon} alt={logoutIcon} width={20} /></Box>
              <Box sx={{
                marginLeft: "3px",
                marginTop:"3px"
              }}>{t("logout")}</Box>
              </ListItem>
            </Link>
          </>
        )}
        {student && (
          <>
            <Link to="/student/notifications">
              <ListItem
                sx={{
                  color: "white",
                  display: "flex",
                  alignItems: "center",
                  padding: "10px 10px 10px",
                  fontSize: "15px"
                }}
              >
                <Box sx={{
                      minWidth: "30px",
                    }}><img src={notificationIcon} alt={notificationIcon} width={20} /></Box>
              <Box sx={{
                marginLeft: "3px",
                marginTop:"3px"
              }}>{t("notifications")}</Box>
              </ListItem>
            </Link>
            <Link to="/student/profile">
              <ListItem
                sx={{
                  color: "white",
                  display: "flex",
                  alignItems: "center",
                  padding: "10px 10px 10px",
                  fontSize: "15px"
                }}
              >
                <Box sx={{
                      minWidth: "30px",
                    }}><img src={profileIcon} alt={profileIcon} width={20} /></Box>
              <Box sx={{
                marginLeft: "3px",
                marginTop:"3px"
              }}>{t("profile")}</Box>
              </ListItem>
            </Link>
            <Link to="/" onClick={handleStudentLogout}>
              <ListItem
                sx={{
                  color: "white",
                  display: "flex",
                  alignItems: "center",
                  padding: "10px 10px 10px",
                  fontSize: "15px"
                }}
              >
              <Box sx={{
                      minWidth: "30px",
                    }}><img src={logoutIcon} alt={logoutIcon} width={20} /></Box>
              <Box sx={{
                marginLeft: "3px",
                marginTop:"3px"
              }}>{t("logout")}</Box>
              </ListItem>
            </Link>
          </>
        )}
        {parent && (
          <>
            <Link to="/" onClick={() => dispatch(logoutParent())}>
              <ListItem
                sx={{
                  color: "white",
                  display: "flex",
                  alignItems: "center",
                  padding: "10px 10px 10px",
                  fontSize: "15px"
                }}
              >
              <Box sx={{
                      minWidth: "30px",
                    }}><img src={logoutIcon} alt={logoutIcon} width={20} /></Box>
              <Box sx={{
                marginLeft: "3px",
                marginTop:"3px"
              }}>{t("logout")}</Box>
              </ListItem>
            </Link>
          </>
        )}
      </List>
    </Box>
  );
  const { data } = useSocialMedia();
  const links = data?.data;
  const whatsAppLink = links
    ?.filter((obj) => obj.type === "Whatsapp")
    .map((obj) => obj.link);

  return (
    <>
      <Box sx={{ display: "flex" }}>
        {/* nav */}
        <AppBar component="nav" sx={{
          background: "#3B82F6ED",
          margin: "1rem 0rem",
          borderRadius: "50px",
        }}>
          <Toolbar className="flex justify-between">
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ mr: 2, ml: 2, display: { md: "none" } }}
            >
              <MenuIcon />
            </IconButton>

            <Box sx={{
              display: { xs: "none", md: "flex" },
              alignItems: "center", 
              justifyContent: "center"
            }}>
              <Link to="/" >
                <ImageLogo src={logoImage} />
              </Link>
              <Link to="/landing">
                <Box
                  sx={{
                    gap: 1,
                    padding: "0 0.6rem",
                    fontSize: "15px"
                  }}
                >
                  {t("search_for_teachers")}
                </Box>
              </Link>
              <Link to="/packages">
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: 1,
                    padding: "0 0.6rem",
                    fontSize: "15px"
                  }}
                >
                  {t("packages")}
                </Box>
              </Link>
              <Link to="/lectures">
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: 1,
                    padding: "0 0.6rem",
                    fontSize: "15px"
                  }}
                >
                  {t("Lectures")}

                </Box>
              </Link>
            </Box>
            <Box>
              <Box
                sx={{
                  display: { xs: "none", md: "flex" },
                  alignItems: "center",
                  justifyContent:"space-around",
                  columnGap: "5px",
                }}
              >
                <ChangeLanguage lang={lang} />
                <SelectCurrency />
                {!teacher && !student && !parent && (
                  <>
                    <Link to="/login">
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: 1,
                          padding: "3px"
                        }}
                      >
                        {t("login")}
                      </Box>
                    </Link>
                    <Link to="/teacherRegister/step1">
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: 1,
                          border: "1px solid",
                          borderRadius: "5px",
                          padding: "6px 4px",
                          color: "#3B82F6ED",
                          backgroundColor: "#FFFFFF",
                        }}
                      >
                        {t("becometeacher")}
                      </Box>
                    </Link>
                  </>
                )}

                {teacher && (
                  <Stack alignItems={"center"} direction="row" gap="12px">
                    <Box
                      sx={{
                        width: "35px",
                        height: "35px",
                        backgroundColor: "#fc5a5a",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        borderRadius: "50%",
                        cursor: "pointer",
                      }}
                      onClick={() => navigate("/teacher/notifications")}
                    >
                      <Badge badgeContent={notSeen} color="success">
                        <NotificationsIcon sx={{ fontSize: "22px" }} />
                      </Badge>
                    </Box>
                    <Box>
                      <Box
                        sx={{
                          color: "white",
                          cursor: "pointer",
                          display: "flex",
                          alignItems: "center",
                          columnGap: "6px",
                        }}
                        onClick={handleClick}
                      >
                        <Box
                          sx={{
                            width: "35px",
                            height: "35px",
                            backgroundColor: "#ffffff33",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            borderRadius: "50%",
                          }}
                        >
                          <PersonIcon sx={{ fontSize: "22px" }} />
                        </Box>
                        {teacher?.firstName
                          ? teacher?.firstName + " " + teacher?.lastName
                          : t("username")}
                      </Box>
                      <Menu
                        id="account-menu"
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        PaperProps={{
                          elevation: 0,
                          sx: {
                            overflow: "visible",
                            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                            mt: 1.5,
                            "& .MuiAvatar-root": {
                              width: 32,
                              height: 32,
                              ml: -0.5,
                              mr: 1,
                            },
                            "&:before": {
                              content: '""',
                              display: "block",
                              position: "absolute",
                              top: 0,
                              right: 14,
                              width: 10,
                              height: 10,
                              bgcolor: "background.paper",
                              transform: "translateY(-50%) rotate(45deg)",
                              zIndex: 0,
                            },
                          },
                        }}
                      >
                        {teacherProfile.map((item) => {
                          return (
                            <MenuItem
                              sx={{ fontSize: "14px" }}
                              onClick={() => {
                                navigate(`/teacher${item.link}`);
                                handleClose();
                              }}
                            >
                              <ListItemIcon>{item.icon}</ListItemIcon>
                              {item.title}
                            </MenuItem>
                          );
                        })}
                        <MenuItem
                          sx={{ fontSize: "14px" }}
                          onClick={handleTeacherLogout}
                        >
                          <ListItemIcon>
                            <LogoutIcon fontSize="small" />
                          </ListItemIcon>
                          {t("logout")}
                        </MenuItem>
                      </Menu>
                    </Box>
                  </Stack>
                )}
                {student && (
                  <Stack direction="row" alignItems={"center"}>
                    <Box
                      sx={{
                        width: "35px",
                        height: "35px",
                        backgroundColor: "#fc5a5a",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        borderRadius: "50%",
                        cursor: "pointer",
                      }}
                      onClick={() => navigate("/student/notifications")}
                    >
                      <Badge badgeContent={notSeen} color="success">
                        <NotificationsIcon sx={{ fontSize: "22px" }} />
                      </Badge>
                    </Box>
                    <Button
                      color="Blue"
                      variant="contained"
                      sx={{ mx: "8px" }}
                      onClick={() => navigate("/student/profile")}
                    >
                      {student?.name ? student.name : t("username")}
                    </Button>
                    <Button
                      color="Blue"
                      variant="contained"
                      onClick={handleStudentLogout}
                    >
                      {t("logout")}
                    </Button>
                  </Stack>
                )}
                {parent && (
                  <Stack alignItems={"center"}>
                    <Button
                      color="Blue"
                      variant="contained"
                      onClick={() => dispatch(logoutParent())}
                    >
                      {t("logout")}
                    </Button>
                  </Stack>
                )}

              </Box>
              <Box
                sx={{
                  display: { xs: "flex", md: "none" },
                  alignItems: "center",
                  columnGap: "10px",
                }}
              >
                {/* <Link to="/landing">
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  color: "white",
                  marginBottom: "12px",
                }}
              >
                <SearchIcon />
              </Box>
            </Link> */}
                <ChangeLanguage lang={lang} />
              </Box>
            </Box>
          </Toolbar>
        </AppBar>

        {/* Drawer */}
        <Box component="nav">
          <Drawer
            container={container}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true,
            }}
            sx={{
              display: { xs: "block", md: "none" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: drawerWidth,
              },
            }}
          >
            {drawer}
          </Drawer>
        </Box>

        <Box component="main" sx={{ paddingY: 0, width: "100%", backgroundColor: "#F5F5F5" }}>
          {props.children}
        </Box>
      </Box>
    </>
  );
}

Navbar.propTypes = {
  window: PropTypes.func,
};

export default Navbar;
