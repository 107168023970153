import * as React from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import instance from '../../../axios/instance';
import { t } from "i18next";
import JobCard from './JobCard';
import NotFound from '../../reusableUi/Not Found';

function JobsData() {
  const [jobs, setAllJobs] = React.useState([]);

  React.useEffect(() => {
    (async () => {
      const response = await instance.get("job/accept-job");
      const data = response.data.data || [];
      setAllJobs([...data]);
    })();
  }, []);
  
  return (
    <Container sx={{ marginTop: "8rem" }}>
      <Box sx={{ width: '100%' ,marginBottom:"80px",textAlign:"center"}}>
                {/* title */}
                <Box sx={{
          fontSize: '2rem',
          fontWeight: 'bold',
          color: '#3B82F6FC',
        }}>
          {t("Your career opportunity starts here!")}
        </Box>
        {/* description */}
        <Box sx={{
          fontSize: '1rem',
          fontWeight: '300',
          color: '#000',
        }}>
          {t("Are you looking for a new job opportunity? The Moalime platform offers you a variety of opportunities in various fields. Additionally, you can now apply for available jobs through the advertisements displayed on our platform. Don't miss this opportunity and start building your professional future with Moalime today.")}
        </Box>
         {
          (!jobs.length) ?
              <NotFound text={t("Unfortunately, there are currently no jobs available.")}/>
            :
            jobs.map((job) => {
                    return <JobCard Jobs={job} />
                })
        }
      </Box>
    </Container>
  )
}
export default JobsData