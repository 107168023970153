import * as React from 'react';
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Button, Container, FormControlLabel, Radio, RadioGroup } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import Cookies from "js-cookie";
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import Navbar from '../../components/Navbar';
import { useForm, Controller } from "react-hook-form";
import instance from "./../../axios/instance";
import Loading from '../../components/Loading';
import PaidIcon from "@mui/icons-material/Paid";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import { useSelector } from 'react-redux';
import useCurrency from '../../hooks/useCurrency';
import { convertCurrency } from '../../utils/convertCurrency';

function LectureInfo() {
  const { teacherId, lectureId } = useParams();
  const { student } = useSelector((state) => state.student);
  const { currency } = useSelector((state) => state.currency);
  const currencyObj = useCurrency(currency);
  const { conversionRate } = useSelector((state) => state.conversionRate);
  const [studentLecture, setStudentLecture] = React.useState(null);
  const navigate = useNavigate();
  const [tolPrice, setTotalPrice] = React.useState(0.0);
  const lang = Cookies.get("i18next") || "en";
  const { t } = useTranslation();
  const [load, setLoad] = React.useState(false);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const {
    register,
    control,
    formState: { errors },
    handleSubmit,
  } = useForm({
    defaultValues: {
      typeofbook: "",
    },
  });
  React.useEffect(() => {
    (async () => {
      const response = await instance(`teacher/lectures/${lectureId}`);
      const result = await convertCurrency(response.data.data.price,response.data.data.currency,currency);
      const data = response.data.data;
      setTotalPrice(result);
      setStudentLecture(data);
    })();
  }, [lectureId, tolPrice,currency]);

  
  async function onSubmit(data) {
    const tmpData = {
      StudentId: student.id,
      TeacherId: teacherId,
      price: studentLecture.price.toFixed(3),
      title: `${studentLecture.titleArabic}`,
      currency: studentLecture.currency,
      LectureId: studentLecture.id,
      typeOfPayment: data.typeofbook,
      period: 1,
      date: new Date(),
      type: "Lecture",
    };
    
    setLoad(true);
    closeSnackbar();
    try {
      const response = await instance.post("payment/booking", tmpData);
      const fetchData = response.data;
      setLoad(false);
      if (data.typeofbook === "wallet") {
        navigate("/student/lecture");
        enqueueSnackbar(
          lang === "ar" ? fetchData.msg.arabic : fetchData.msg.english,
          { variant: "success", autoHideDuration: 8000 }
        );
      } else {
        window.location.replace(fetchData.data);
      }
    } catch (err) {
      setLoad(false);
      const message = err.response?.data.message ? err.response?.data.message : t("error");
      enqueueSnackbar(t(message), {
        variant: "error",
        autoHideDuration: "5000",
      });
    }
  }

  if (!studentLecture) return <Loading />
  else return (
    <Navbar>
      <Container sx={{ marginY: "100px" }}>
        <Card variant="outlined" sx={{ width: "100%" }}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Box sx={{ p: 2 }}>
              <Controller
                name="typeofbook"
                control={control}
                render={({ field }) => (
                  <RadioGroup {...field}>
                    <FormControlLabel
                      value="wallet"
                      control={<Radio size="2px" />}
                      label={`${t("credit")} (${t("wallet")})`}
                    />
                    <FormControlLabel
                      value="thawani"
                      control={<Radio size="2px" />}
                      label={t("gateway")}
                    />
                  </RadioGroup>
                )}
                {...register("typeofbook", {
                  required: "typeofbook Address is required",
                })}
              />
              {errors.typeofbook?.type === "required" && (
                <Typography
                  color="error"
                  role="alert"
                  sx={{ fontSize: "13px", marginTop: "6px" }}
                >
                  {t("required")}
                </Typography>
              )}
            </Box>
            <Box sx={{ p: 2 }}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  marginBottom: "24px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    columnGap: "6px",
                  }}
                >
                  <Box
                    sx={{
                      backgroundColor: "#005B8E",
                      color: "white",
                      width: "35px",
                      height: "35px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      borderRadius: "50%",
                    }}
                  >
                    <LocalOfferIcon sx={{ fontSize: "15px" }} />
                  </Box>
                  <Typography sx={{ fontSize: "14px" }}>
                    {t("Lecture Price")}
                  </Typography>
                </Box>
                <Typography>
                {tolPrice} {t(currency)}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  marginBottom: "14px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    columnGap: "6px",
                  }}
                >
                  <Box
                    sx={{
                      backgroundColor: "#005B8E",
                      color: "white",
                      width: "35px",
                      height: "35px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      borderRadius: "50%",
                    }}
                  >
                    <PaidIcon sx={{ fontSize: "15px" }} />
                  </Box>
                  <Typography sx={{ fontSize: "14px" }}>
                    {t("totla Lecture Price")}
                  </Typography>
                </Box>
                <Typography>
                  {tolPrice} {t(currency)}
                </Typography>
              </Box>
            </Box>
            <div className='mb-2'>
              {!load ? (
                <Button fullWidth type="submit" variant="contained">
                  {t("next")}
                </Button>
              ) : (
                <Button fullWidth variant="contained">
                  {t("next")}...
                </Button>
              )}
            </div>
          </form>
        </Card>
      </Container>
    </Navbar>
  )
}

export default LectureInfo;
