import {
  Avatar,
  Box,
  Container,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Paper,
  Typography,
  IconButton,
  Drawer,
  ListItemIcon,
} from "@mui/material";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import Navbar from "../Navbar";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { deepOrange } from "@mui/material/colors";

// استيراد الأيقونات المطلوبة
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import SettingsIcon from "@mui/icons-material/Settings";
import SchoolIcon from "@mui/icons-material/School";
import LockIcon from "@mui/icons-material/Lock";
import MessageIcon from "@mui/icons-material/Message";
import PaymentIcon from "@mui/icons-material/Payment";
import SupportIcon from "@mui/icons-material/Support";
import MenuIcon from "@mui/icons-material/Menu";
import RequestPageIcon from '@mui/icons-material/RequestPage';
import GradingIcon from '@mui/icons-material/Grading';
export default function StudentLayout({ children }) {
  const { t } = useTranslation();
  const { student } = useSelector((state) => state.student);
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  // تحديث المصفوفة بإضافة أيقونات
  const topics = [
    { title: t("profile"), link: "/profile", icon: <AccountCircleIcon /> },
    { title: t("profile_photo"), link: "/profile_photo", icon: <PhotoCameraIcon /> },
    { title: t("Lesson booking requests"), link: "/request-lesson", icon: <GradingIcon /> },
    { title: t("alllessons"), link: "/allLessons", icon: <MenuBookIcon /> },
    { title: t("pastlessons"), link: "/pastLessons", icon: <MenuBookIcon /> },
    { title: t("cominglessons"), link: "/comingLessons", icon: <MenuBookIcon /> },
    { title: t("packages"), link: "/package", icon: <LocalOfferIcon /> },
    { title: t("Lectures"), link: "/lecture", icon: <SchoolIcon /> },
    { title: t("Questions"), link: "/questions", icon: <MenuBookIcon /> },
    { title: t("Exams"), link: "/exam", icon: <MenuBookIcon /> },
    { title: t("Resource"), link: "/resource", icon: <MenuBookIcon /> },
    { title: t("Discounts"), link: "/discount", icon: <LocalOfferIcon /> },
    { title: t("my_teachers"), link: "/teachers", icon: <SchoolIcon /> },
    { title: t("Change Password"), link: "/settings", icon: <LockIcon /> },
    { title: t("messages"), link: "/messages", icon: <MessageIcon /> },
    { title: t("credit"), link: "/credit", icon: <PaymentIcon /> },
    { title: t("payment_history"), link: "/payment-history", icon: <PaymentIcon /> },
    { title: t("paymentOperations"), link: "/financial-records", icon: <PaymentIcon /> },
    { title: t("Complaints"), link: "/technical-support", icon: <SupportIcon /> },
    { title: t("My bills"), link: "/mybills", icon: <RequestPageIcon /> },
    { title: t("settings"), link: "/notify", icon: <SettingsIcon /> },
  ];

  const drawer = (
    <Paper sx={{ padding: "20px", width: 250 }}>
      <Box sx={{ display: "flex", alignItems: "center", flexDirection: "column" }}>
        <Avatar
          src={`${process.env.REACT_APP_API_KEY}images/${student?.image}`}
          alt={student?.name}
          sx={{
            width: 95,
            height: 95,
            fontSize: 36,
            bgcolor: deepOrange[500],
          }}
        />
        <Typography sx={{ marginTop: 2, fontWeight: "700" }}>
          {student?.name}
        </Typography>
      </Box>
      <Divider sx={{ marginY: 2 }} />
      <List>
        {topics.map((topic, index) => (
          <Link key={index} to={`/student${topic.link}`} style={{ textDecoration: "none", color: "inherit" }}>
            <ListItem disablePadding>
              <ListItemButton>
                <ListItemIcon sx={{ minWidth: 40 }}>{topic.icon}</ListItemIcon>
                <ListItemText primary={topic.title} sx={{ textAlign: "start" }} />
              </ListItemButton>
            </ListItem>
          </Link>
        ))}
      </List>
    </Paper>
  );

  return (
    <Navbar>
      <Container sx={{ marginTop: "120px", marginBottom: "60px" }}>
        <Grid container spacing={2}>
          {/* زر القائمة للجوال */}
          <Grid item xs={12} sx={{ display: { md: "none" }, textAlign: "left" }}>
            <IconButton onClick={handleDrawerToggle}>
              <MenuIcon />
            </IconButton>
          </Grid>

          {/* القائمة الجانبية - تظهر دائمًا في الشاشات الكبيرة */}
          <Grid item xs={12} md={3} sx={{ display: { xs: "none", md: "block" } }}>
            {drawer}
          </Grid>

          {/* القائمة الجانبية للجوال */}
          <Drawer
            anchor="left"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            sx={{
              display: { xs: "block", md: "none" },
              "& .MuiDrawer-paper": { width: 250 },
            }}
          >
            {drawer}
          </Drawer>

          {/* المحتوى الرئيسي */}
          <Grid item xs={12} md={9} sx={{ overflow: "hidden" }}>
            {children}
          </Grid>
        </Grid>
      </Container>
    </Navbar>
  );
}
