import React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TablePagination from "@mui/material/TablePagination";
import { Link } from "react-router-dom";
import TableRow from "@mui/material/TableRow";
import DoneIcon from "@mui/icons-material/Done";
import { Box, Button, TableBody } from "@mui/material";
import { useTranslation } from "react-i18next";
import Loading from "../../components/Loading";
import Cookies from "js-cookie";
import { useState, useEffect } from "react";
import { useSnackbar } from "notistack";
import ClearIcon from "@mui/icons-material/Clear";
import { useMyPendingLectures } from "../../hooks/useMyPendingLectures";

export default function LecturesCategoriesPendingByAdmin() {
    const { t } = useTranslation();

    const columns = [
        { id: "Image", label: t("Photo"), minWidth: 120 },
        { id: "title", label: t("title"), minWidth: 120 },
        { id: "subject", label: t("subject"), minWidth: 120 },
        { id: "class", label: t("classes"), minWidth: 120 },
        { id: "semester", label: t("semester"), minWidth: 120 },
        { id: "studycurriculums", label: t("studycurriculums"), minWidth: 150 },
        { id: "price", label: t("price"), minWidth: 120 },
        { id: "currency", label: t("currency"), minWidth: 120 },
        { id: "linkLecture", label: t("Link Lecture"), minWidth: 120 },
        { id: "Lecturedocuments", label: t("Lecture documents"), minWidth: 120 },
        { id: "description_course_en", label: t("description"), minWidth: 120 },
        { id: "actions", label: t("actions"), minWidth: 120 },
    ];

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const { data, isLoading } = useMyPendingLectures();

    const [categories, setCategoires] = useState([]);

    const { closeSnackbar, enqueueSnackbar } = useSnackbar();
    const lang = Cookies.get("i18next") || "en";

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };


    // Added by Abdelwahab
    const handleAccept = async (id) => {
        closeSnackbar();
        try {
            const res = await fetch(
                `${process.env.REACT_APP_API_KEY}api/v1/teacher/lectures/status/accept-lecture/${id}`,
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json"
                    },
                }
            );

            enqueueSnackbar(t("Activation process was successful."), { variant: "success", autoHideDuration: 8000 });
            filterTeachers(id)
        } catch (err) {
            enqueueSnackbar(t("Activation failed."), { variant: "error", autoHideDuration: 8000 });
        }
    };
    const rejectLecture = async (id) => {
        closeSnackbar();
        try {
            const res = await fetch(
                `${process.env.REACT_APP_API_KEY}api/v1/teacher/lectures/delete-lecture/${id}`,
                {
                    method: "DELETE",
                    headers: {
                        "Content-Type": "application/json"
                    },
                }
            );

            enqueueSnackbar(t("The lecture offer has been declined."), { variant: "success", autoHideDuration: 8000 });
            filterTeachers(id)
        } catch (err) {
            enqueueSnackbar(t("An error occurred while declining the lecture offer."), { variant: "error", autoHideDuration: 8000 });
        }
    };
    useEffect(() => {
        if (data?.data) {
            setCategoires(data.data);
        }
    }, [data]);

    function filterTeachers(id) {
        const filteredTeachers = categories.filter(
            (teacher) => teacher.id.toString() !== id.toString()
        );
        setCategoires(filteredTeachers);
    }

    return (
        <Box>
            {!isLoading ? (
                <Paper sx={{ width: "100%", padding: "20px" }}>
                    <TableContainer sx={{ maxHeight: 440 }}>
                    <Table stickyHeader aria-label="sticky table">
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={"center"}
                    style={{ top: 57, minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
              <TableBody>
                {categories
                  ?.map((row) => {
                    return (
                      <TableRow hover role="checkbox" key={row.id + "demj"}>
                        <TableCell align="center"><img src={row.image ? `${process.env.REACT_APP_API_KEY}images/${row.image}` : "/logo.png"} alt={row.image} width={"100px"} /></TableCell>
                        <TableCell align="center">
                          {Cookies.get("i18next") === "ar"
                            ? row.titleArabic
                            : row.titleEnglish}
                        </TableCell>
                        <TableCell align="center">
                          {Cookies.get("i18next") === "ar"
                            ? row.subject?.titleAR
                            : row.subject?.titleEN}
                        </TableCell>
                        <TableCell align="center">
                          {Cookies.get("i18next") === "ar"
                            ? row.class?.titleAR
                            : row.class?.titleEN}
                        </TableCell>
                        <TableCell align="center">
                          {t(row?.semester)}
                        </TableCell>
                        <TableCell align="center">
                          {lang === "ar" ? row?.curriculums?.titleAR : row?.curriculums?.titleEN}
                        </TableCell>
                        <TableCell align="center">
                          {row.price}
                        </TableCell>
                        <TableCell align="center">
                          {t(row.currency)}
                        </TableCell>
                          {row?.linkLecture ? <TableCell align="center">
                            <Button
                              component={Link}
                              to={row.linkLecture}
                              target="_blank" // يفتح الرابط في صفحة جديدة
                              rel="noopener noreferrer"
                            >
                              {t("Test documents")}
                            </Button>
                          </TableCell> : <>
                        <TableCell align="center">
                        {t("No Link Lecture")}
                        </TableCell>
                          </>}
                        {row?.docs ? <TableCell align="center">
                            <Button
                              component={Link}
                              to={`${process.env.REACT_APP_API_KEY}images/${row.docs}`}
                              target="_blank" // يفتح الرابط في صفحة جديدة
                              rel="noopener noreferrer"
                            >
                              {t("Lecture documents")}
                            </Button>
                          </TableCell> : <>
                          <TableCell align="center">{t("No Documents")}</TableCell>
                          </>}
                        <TableCell align="center">
                          {Cookies.get("i18next") === "ar"
                            ? row.descriptionArabic
                            : row.descriptionEnglish}
                        </TableCell>
                        
                        <TableCell align="center">

<Button color="success">
    <DoneIcon onClick={() => handleAccept(row.id)} />
</Button>
<Button
    color="error"
    onClick={() => rejectLecture(row.id)}
>
    <ClearIcon />
</Button>

</TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[10, 25, 100]}
                        component="div"
                        count={categories.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Paper>
            ) : (
                <Loading />
            )}
        </Box>
    );
}
