import { useQuery } from "react-query";

async function getAdvertisements() {
    const response = await fetch(
      `${process.env.REACT_APP_API_KEY}api/v1/advertisement/accept-advertisement`
    );
    const data = await response.json();
    return data;
  }

  export const useAdvertisements = () => {
    return useQuery("get-advertisements", getAdvertisements);
  };
