import { Box } from '@mui/material'
import React from 'react'
import notFound from '../../images/notFound.svg'

function NotFound({text}) {
  return (
    <>
    <Box sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        background: '#f5f5f5'
       , padding: '20px'
    }}>
        <img src={notFound} alt="" width={300} />
    </Box>
    <Box sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        background: '#f5f5f5',
        fontSize: '30px',
        color: "#3B82F6FC"
    }}>
        {text}
    </Box>
    </>
    
  )
}

export default NotFound
