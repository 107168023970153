import React, { useEffect, useState } from 'react';
import Navbar from '../Navbar';
import { Container, TextField, Select, Box, Button } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import { t } from 'i18next';
import { Controller, useForm } from 'react-hook-form';
import currencies from '../../data/currencies';
import Cookies from 'js-cookie';
import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import Loading from '../Loading';

function EditResource() {
    const { id } = useParams()
    const [levels, setLevels] = useState([]);
    const [error, setError] = useState(null);
    const lang = Cookies.get("i18next") || "en";
    const [file, setFile] = useState();
    const { closeSnackbar, enqueueSnackbar } = useSnackbar();
    
    const [resource, setResource] = useState()
    const [loading, setLoading] = useState(false);
    const { teacher } = useSelector((state) => state.teacher);
    const i18next = Cookies.get('i18next');
    const [subjects, setSubjects] = useState([])
    function convertDate(dateString) {
        const date = new Date(dateString);
        return date.toISOString().split('T')[0]; // yyyy-mm-dd
    }
    const { register, handleSubmit, formState: { errors }, control, reset } = useForm({
        defaultValues: {
            level: '',
            subject: '',
            resource: '',
            video: '',
            file: '',
            price: '',
            currency: '',
            startDate: '',
            endDate: '',
            image:'',
            duration:''
        }
    });

    useEffect(() => {
        const fetchLevels = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_KEY}api/v1/admin/levels`);
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                const data = await response.json();
                setLevels(data.data);
            } catch (err) {
                console.error("Error fetching levels:", err);
                setError(err.message);
            }
        };
        const getSubjects = async () => {
            const response = await axios.get(`${process.env.REACT_APP_API_KEY}api/v1/subject/allSubjects`);
            setSubjects(response.data.data);
        };
        const fetchResourceData = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_KEY}api/v1/resources/${id}`);
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                const data = await response.json();
                setResource(data.data); // Assuming the package contains a level property
                setLoading(true)
            } catch (err) {
                setError(err.message);
            }
        };

        // Fetch data
        fetchLevels();
        getSubjects();
        fetchResourceData();

    }, [id]);

    useEffect(() => {
        if (resource) {
            reset({
                level: resource.level.id,
                subject: resource.subject.id,
                resource: resource.resource,
                video: resource.video,
                price: resource.price,
                currency: resource.currency,
                startDate: convertDate(resource.startDate),
                endDate: convertDate(resource.endDate),
                image:resource.image,
                duration:resource.duration
            });
        }
    }, [resource, reset]);

    const navigate = useNavigate();

    function handleFile(e) {
        setFile(e.target.files[0]);

    }

    async function onSubmit(data) {
        closeSnackbar();
         const startDateTime = new Date(data.startDate); // تحويل إلى كائن تاريخ
            const endDateTime = new Date(data.endDate); // تحويل إلى كائن تاريخ
            const differenceInMilliseconds = endDateTime - startDateTime;
            const differenceInSeconds = Math.floor(differenceInMilliseconds / (1000 * 60 * 60 * 24));
            // تحقق من أن startDate أقل من endDate
            if (startDateTime >= endDateTime) {
              enqueueSnackbar(t("Start date must be earlier than end date"), { variant: "error", autoHideDuration: 5000 });
              return; // إنهاء الوظيفة إذا كان الشرط غير محقق
            }
            data.duration=differenceInSeconds
            data.startDate = startDateTime;
            data.endDate = endDateTime;
            const tmpData = { ...data, teacherId: teacher.id };
            const formData = new FormData();
            if(file)
            formData.append("image", file);
            for (const key in tmpData) {
              formData.append(key, tmpData[key]);
            }
        try {
            await axios.put(`${process.env.REACT_APP_API_KEY}api/v1/resources/edit-resource/${id}`, formData);
            setLoading(true);
            enqueueSnackbar(t("resourseEditMsg"), { variant: "success", autoHideDuration: "5000" });
            navigate("/teacher/resources")
        } catch (err) {
            enqueueSnackbar(t("resourseEditErrMsg"), { variant: "error", autoHideDuration: "5000" });
            setLoading(false);
            throw new Error("Something went wrong's " + err);
        }
    }


    return (
        <Navbar>
            {loading ? <>
                <Container sx={{ marginTop: '120px', marginBottom: '80px' }}>
                    <h1 className="text-3xl text-center my-4">{t("Edit Resource")}</h1>
                    <form style={{ maxWidth: "50%", margin: 'auto' }} encType="multipart/form-data" onSubmit={handleSubmit(onSubmit)}>
                        <Box sx={{ marginBottom: "18px" }}>
                            <InputLabel sx={{ marginBottom: "6px", fontSize: "14px" }}>
                                {t("subject")}
                            </InputLabel>
                            <Controller
                                name="subject"
                                control={control}
                                render={({ field }) => (
                                    <Select
                                        {...field}
                                        fullWidth
                                        {...register("subject", {
                                            required: t("isRequired"),
                                        })}
                                    >
                                        {i18next === "ar"
                                            ? subjects.map((subject, index) => (
                                                <MenuItem key={index} value={subject.id}>{t(subject.titleAR)}</MenuItem>
                                            ))
                                            : subjects.map((subject, index) => (
                                                <MenuItem key={index} value={subject.id}>{t(subject.titleEN)}</MenuItem>
                                            ))
                                        }
                                    </Select>
                                )}
                                rules={{ required: t("required") }}
                            />
                        </Box>
                        <Box sx={{ marginBottom: "18px" }}>
                            <InputLabel sx={{ marginBottom: "6px", fontSize: "14px" }}>
                                {t("level")}
                            </InputLabel>
                            <Controller
                                name="level"
                                control={control}
                                render={({ field }) => (
                                    <Select
                                        {...field}
                                        fullWidth
                                        {...register("level", {
                                            required: t("isRequired"),
                                        })}
                                    >
                                        {i18next === "ar"
                                            ? levels.map((level, index) => (
                                                <MenuItem key={index} value={level.id}>{t(level.titleAR)}</MenuItem>
                                            ))
                                            : levels.map((level, index) => (
                                                <MenuItem key={index} value={level.id}>{t(level.titleEN)}</MenuItem>
                                            ))
                                        }
                                    </Select>
                                )}
                                rules={{ required: t("required") }}
                            />
                        </Box>
                        <Box sx={{ marginBottom: "18px" }}>
                            <InputLabel sx={{ marginBottom: "6px", fontSize: "14px" }}>
                                {t("resource")}
                            </InputLabel>
                            <Controller
                                name="resource"
                                control={control}
                                render={({ field }) => (
                                    <Select
                                        {...field}
                                        fullWidth
                                        {...register("resource", {
                                            required: t("isRequired"),
                                        })}
                                    >
                                        <MenuItem value="Lecture">{t("Lecture")}</MenuItem>
                                        <MenuItem value="Exam">{t("Exam")}</MenuItem>
                                        <MenuItem value="Reviews">{t("Reviews")}</MenuItem>
                                        <MenuItem value="Summary of the material">{t("Summary of the material")}</MenuItem>
                                    </Select>
                                )}
                                rules={{ required: t("required") }}
                            />
                        </Box>
                        <Box sx={{ marginBottom: "18px" }}>
                            <InputLabel sx={{ marginBottom: "6px", fontSize: "14px" }}>
                                {t("startDate")}
                            </InputLabel>
                            <Controller
                                name="startDate"
                                control={control}
                                render={({ field }) => <TextField type='date' {...field} fullWidth />}
                                rules={{ required: t("required") }}
                            />
                        </Box>
                        <Box sx={{ marginBottom: "18px" }}>
                            <InputLabel sx={{ marginBottom: "6px", fontSize: "14px" }}>
                                {t("End Date")}
                            </InputLabel>
                            <Controller
                                name="endDate"
                                control={control}
                                render={({ field }) => <TextField type='date' {...field} fullWidth />}
                                rules={{ required: t("required") }}
                            />
                        </Box>

                        <Box sx={{ marginBottom: "18px" }}>
                            <InputLabel sx={{ marginBottom: "6px", fontSize: "14px" }}>
                                {t("link")}
                            </InputLabel>
                            <Controller
                                name="video"
                                control={control}
                                render={({ field }) => <TextField  {...field} fullWidth />}
                                
                            />
                        </Box>
                        <Box sx={{ marginBottom: "18px" }}>
                            <InputLabel sx={{ marginBottom: "6px", fontSize: "14px" }}>
                                {t("price")}
                            </InputLabel>
                            <Controller
                                name="price"
                                control={control}
                                render={({ field }) => <TextField  {...field} fullWidth />}
                                rules={{ required: t("required") }}
                            />
                        </Box>
                        <Box sx={{ marginBottom: "18px" }}>
                            <InputLabel sx={{ marginBottom: "6px", fontSize: "14px" }}>
                                {t("currency")}
                            </InputLabel>
                            <Controller
                                name="currency"
                                control={control}
                                render={({ field }) => (
                                    <Select
                                        {...field}
                                        fullWidth
                                        {...register("currency", {
                                            required: t("isRequired"),
                                        })}
                                    >
                                        {
                                            currencies.map((curr,index) => {
                                                return <MenuItem key={index} value={curr.title}>{lang === "en" ? curr.titleEn : curr.titleAr}</MenuItem>
                                            })
                                        }
                                    </Select>
                                )}
                                rules={{ required: t("required") }}
                            />
                        </Box>
                        <Box sx={{ marginBottom: "18px" }}>
                            <InputLabel id="image">{t("chooseImage")}</InputLabel><br />
                            <input type="file" onChange={handleFile} />
                        </Box>
                        <br />
                        <br />
                        <Button
                             type="submit"
                            variant="contained"
                        >{t("Edit Package")}
                        </Button>
                    </form>
                </Container>
            </> : <>
                <Loading />
            </>}

        </Navbar>
    )
}

export default EditResource;
