import { Avatar, Box, Typography, Button, Divider } from "@mui/material";
import React, { useState, useCallback, useMemo } from "react";
import DateRangeIcon from "@mui/icons-material/DateRange";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import ApartmentIcon from "@mui/icons-material/Apartment";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { collection, query, where, getDocs, addDoc, Timestamp } from "firebase/firestore";
import { db } from "../../firebase";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";

export default function BookedLesson({
  image,
  name,
  date,
  type,
  period,
  isStudent,
  studentAccept,
  teacherAccept,
  sessionId,
  parentTeacher,
  parentStudent,
  startedAt,
  endedAt
}) {
  const { t } = useTranslation();
  const teacher = useSelector((state) => state.teacher);
  const student = useSelector((state) => state.student);
  const [edited, setEdited] = useState(false);
  const navigate = useNavigate();
  const { closeSnackbar, enqueueSnackbar } = useSnackbar();

  // تحديد زر تأكيد الحضور بناءً على الدور (الطالب أو المعلم)
  const [showAttend, setShowAttend] = useState(
    (isStudent && !studentAccept) || (!isStudent && !teacherAccept)
  );

  // دالة قبول الدرس (تأكيد الحضور)
  const acceptLesson = useCallback(async () => {
    try {
      const url = isStudent
        ? `${process.env.REACT_APP_API_KEY}api/v1/student/acceptLesson/${student?.student.id}`
        : `${process.env.REACT_APP_API_KEY}api/v1/teacher/acceptLesson/${teacher?.teacher.id}`;

      const response = await fetch(url, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: isStudent ? student?.token : teacher?.token,
        },
        body: JSON.stringify({ SessionId: sessionId }),
      });

      if (!response.ok) throw new Error("Failed to accept lesson");

      setShowAttend(false);
    } catch (err) {
      console.error("Error accepting lesson:", err);
    }
  }, [sessionId, student, teacher, isStudent]);

  // دالة لتحديث حالة بدء أو إنهاء الدرس
  const handleLessonStartAndEnd = useCallback(async () => {
    try {
      const url = isStudent
        ? `${process.env.REACT_APP_API_KEY}api/v1/student/startLesson/${student?.student.id}`
        : `${process.env.REACT_APP_API_KEY}api/v1/teacher/endLesson/${teacher?.teacher.id}`;

      const response = await fetch(url, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: isStudent ? student?.token : teacher?.token,
        },
        body: JSON.stringify({ SessionId: sessionId }),
      });

      if (!response.ok) throw new Error("Failed to update lesson status");

      closeSnackbar();
      enqueueSnackbar(t("update_success"), {
        variant: "success",
        autoHideDuration: 1000,
      });
      setEdited(true);
    } catch (err) {
      console.error("Error updating lesson status:", err);
      closeSnackbar();
      enqueueSnackbar("Something went wrong, please try again later", {
        variant: "error",
        autoHideDuration: 1000,
      });
    }
  }, [sessionId, student, teacher, isStudent, closeSnackbar, enqueueSnackbar, t]);

  // دالة لإنشاء محادثة جديدة
  const handleCreateMessage = useCallback(async () => {
    try {
      const q = query(
        collection(db, "chats"),
        where("studentId", "==", `${parentStudent?.id}`),
        where("teacherId", "==", `${parentTeacher?.id}`)
      );

      const res = await getDocs(q);
      if (res.empty) {
        await addDoc(collection(db, "chats"), {
          messages: [],
          teacherId: `${parentTeacher?.id}`,
          studentId: `${parentStudent?.id}`,
          studentName: parentStudent?.name,
          studentImage: parentStudent?.image,
          teacherName: `${parentTeacher?.firstName} ${parentTeacher?.lastName}`,
          teacherImage: parentTeacher?.image,
          lastmessage: Timestamp.now(),
        });
      }

      navigate(isStudent ? `/student/messages` : `/teacher/messages`);
    } catch (err) {
      console.error("Error creating message:", err);
    }
  }, [parentStudent, parentTeacher, navigate, isStudent]);

  // تنسيق التاريخ لتحسين الأداء
  const formattedDate = useMemo(() => new Date(date).toLocaleString(), [date]);

  return (
    <>
      <Box sx={{ marginY: "15px" }}>
        <Box sx={{ display: "flex", columnGap: "12px" }}>
          <Avatar
            sx={{ width: "85px", height: "85px" }}
            src={`${process.env.REACT_APP_API_KEY}images/${image}`}
          />
          <Box>
            <Typography sx={{ fontWeight: "600" }}>{name}</Typography>
            <Box sx={{ marginY: "8px", display: "flex", alignItems: "center", columnGap: "6px" }}>
              <DateRangeIcon sx={{ fontSize: "16px", color: "#9D9D9D" }} />
              <Typography sx={{ fontSize: "13px", color: "#9D9D9D" }}>
                {formattedDate}
              </Typography>
            </Box>
            <Box sx={{ marginY: "8px", display: "flex", alignItems: "center", columnGap: "6px" }}>
              <ApartmentIcon sx={{ fontSize: "16px", color: "#9D9D9D" }} />
              <Typography sx={{ fontSize: "13px", color: "#9D9D9D" }}>
                {t(type + "_place")}
              </Typography>
            </Box>
            <Box sx={{ marginY: "8px", display: "flex", alignItems: "center", columnGap: "6px" }}>
              <AccessTimeIcon sx={{ fontSize: "16px", color: "#9D9D9D" }} />
              <Typography sx={{ fontSize: "13px", color: "#9D9D9D" }}>
                {period} {t("lesson_hour")}
              </Typography>
            </Box>

            {/* زر تأكيد حضور الطالب */}
            {isStudent && !studentAccept && (
              <Button
                onClick={acceptLesson}
                variant="contained"
                size="small"
                sx={{ marginTop: "4px", marginBottom: "8px" }}
              >
                {t("attendLesson")}
              </Button>
            )}

            {/* زر تأكيد حضور المعلم */}
            {!isStudent && !teacherAccept && (
              <Button
                onClick={acceptLesson}
                variant="contained"
                size="small"
                sx={{ marginTop: "4px", marginBottom: "8px" }}
              >
                {t("attendLesson")}
              </Button>
            )}

            {/* زر المحادثة */}
            <Button
              onClick={handleCreateMessage}
              variant="contained"
              size="small"
              sx={{ marginTop: "4px", marginBottom: "8px", marginX: "16px" }}
              color="warning"
            >
              {t("instant_messaging")}
            </Button>

            {/* زر بدء الدرس (للطلاب) */}
            {isStudent && !startedAt && !edited && (
              <Button
                onClick={handleLessonStartAndEnd}
                variant="contained"
                size="small"
                sx={{ marginTop: "4px", marginBottom: "8px", marginX: "16px" }}
                color="success"
              >
                {t("start_lesson")}
              </Button>
            )}

            {/* زر إنهاء الدرس (للمعلمين) */}
            {!isStudent && startedAt && !edited && !endedAt && (
              <Button
                onClick={handleLessonStartAndEnd}
                variant="contained"
                size="small"
                sx={{ marginTop: "4px", marginBottom: "8px", marginX: "16px" }}
                color="success"
              >
                {t("end_lesson")}
              </Button>
            )}
          </Box>
        </Box>
      </Box>
      <Divider />
    </>
  );
}
