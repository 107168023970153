import { Box, Container, Typography, styled, Grid } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom'

const Image = styled("img")({
    width: "100%",
    borderRadius: "15px"
})

export default function HomeWorks() {
    const { t } = useTranslation()
    const data = [
        {
            image: "https://server.moalime.com/drive/work1.png",
            title: t('work1_title'),
            description: t('work1_desc')
        },
        {
            image: "https://server.moalime.com/drive/work2.jpeg",
            title: t('work2_title'),
            description: t('work2_desc')
        },
        {
            image: "https://server.moalime.com/drive/work3.jpeg",
            title: t('work3_title'),
            description: t('work3_desc')
        },
        {
            image: "https://server.moalime.com/drive/work4.png",
            title: t('work4_title'),
            description: t('work4_desc')
        },
    ]
    return (
        <Box sx={{ marginY: "60px" }}>
            <Container>
                <Typography sx={{
                    fontSize: { md: "26px", xs: "22px" }, fontWeight: "700", color: "#3B82F6ED", textAlign: "center",
                    marginBottom: "50px"
                }}>{t('websiteWork')}</Typography>
                {
                    data.map((item, index) => {
                        return (
                            <Grid container spacing={2} key={index + 'm1'} sx={{ marginBottom: { md: "12px", xs: "30px" } }}>
                                <Grid item xs={10} sm={6} md={4} lg={3} sx={{ margin: "auto" }}><Image src={item.image} /></Grid>
                                <Grid item xs={12} md={8} lg={9} sx={{ textAlign: { xs: "center", md: "start" } }}>
                                    <Box sx={{ display: "flex", alignItems: "center", marginBottom: "1rem" }}>
                                        <Typography sx={{ fontSize: "22px", color: "#3B82F6ED", fontWeight: "700" }}>0{index + 1}</Typography>
                                        <Typography sx={{ fontSize: "22px", color: "#3B82F6ED", fontWeight: "700", marginLeft: "1rem" }}>{item.title}</Typography>
                                    </Box>
                                    <Typography sx={{ fontSize: "14px", color: "#6D6D6D", width: { md: "80%", xs: "100%" } }}>{item.description}</Typography>
                                </Grid>
                            </Grid>
                        )
                    })
                }
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <Link to="/teacherRegister/step1">
                        <Box
                            sx={{
                                width: "250px",
                                gap: 1,
                                border: "1px solid",
                                borderRadius: "5px",
                                padding: "6px 4px",
                                color: "#FFF",
                                backgroundColor: "#3B82F6ED",
                                textAlign: "center",
                            }}
                        >
                            {t("becometeacher")}
                        </Box>
                    </Link>
                </Box>
            </Container>
        </Box>
    )
}
