import React, { useState } from "react";
import AdminLayout from "../../components/admin/AdminLayout";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { Box, Button, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import Loading from "../../components/Loading";
import { useSelector } from "react-redux";
import { useAdminTeachers } from "../../hooks/useAdminTeachers";
import LocalAtmIcon from "@mui/icons-material/LocalAtm";
import { useNavigate } from "react-router-dom";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DeleteIcon from "@mui/icons-material/Delete";
import { useSnackbar } from "notistack";
// Added by Abdelwahab
import EmailIcon from "@mui/icons-material/Email";
import TextField from "@mui/material/TextField";
import DoDisturbOnIcon from "@mui/icons-material/DoDisturbOn";
import DoDisturbOffIcon from "@mui/icons-material/DoDisturbOff";
import BuildIcon from "@mui/icons-material/Build";
import { collection, addDoc, Timestamp } from "firebase/firestore";
import { db } from "../../firebase";
import Cookies from "js-cookie";
import { useAdminParents } from "../../hooks/useAdminParents";

export default function AdminParents() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { token } = useSelector((state) => state.admin);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchInput, setSearchInput] = useState("");
  const { lang } = Cookies.get("i18next") || "en";

  const columns = [
    { id: "Name", label: t("name"), minWidth: 150 },
    { id: "Email", label: t("email"), minWidth: 150 },
    { id: "delete", label: t("delete"), minWidth: 150 },
  ];
  const { closeSnackbar, enqueueSnackbar } = useSnackbar();
  const { data, isLoading } = useAdminParents(token);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };


  const handleDelete = (id) => {
    try {
      fetch(
        `${process.env.REACT_APP_API_KEY}api/v1/admin/deleteParent/${id}`,
        {
          method: "DELETE",
          headers: {
            Authorization: token,
          },
        }
      );
      closeSnackbar();
      enqueueSnackbar("Teacher Deleted Successfully", {
        variant: "success",
        autoHideDuration: 4000,
      });
      window.location.reload();
    } catch (err) {
      console.error(err);
    }
  };


  return (
    <AdminLayout>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "30px",
          marginTop: "20px",
        }}
      >
        <Typography sx={{ fontSize: "20px", fontWeight: "500" }}>
          {t("parents")}
        </Typography>
      </Box>

      {!isLoading ? (
        <Paper sx={{ width: "100%", padding: "20px" }}>
          <TableContainer
            sx={{
              maxHeight: 440,
            }}
          >

            <Table stickyHeader aria-label="sticky table">
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={"center"}
                    style={{ top: 57, minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
              <TableBody>
                {data?.data.length > 0 &&
                  data.data
                    .filter(
                      (row) =>
                        `${row.firstName + " " + row.lastName || ""}`
                          .toLowerCase()
                          .includes(searchInput.toLowerCase().trim()) ||
                        `${row.email || ""}`
                          .toLowerCase()
                          .includes(searchInput.toLowerCase().trim()) ||
                        `${row.gender || ""}`
                          .toLowerCase()
                          .startsWith(searchInput.toLowerCase().trim()) ||
                        `${row.phone || ""}`
                          .toLowerCase()
                          .includes(searchInput.toLowerCase().trim())
                    )
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => {
                      return (
                        <TableRow hover role="checkbox" key={row.id + "demj"}>
                          <TableCell align="center">
                            {row.name || ""}
                          </TableCell>
                          <TableCell align="center">{row.email}</TableCell>
                         
                          <TableCell align="center">
                            <Button
                              onClick={() => handleDelete(row.id)}
                              sx={{ minWidth: "10px" }}
                              color="error"
                            >
                              <DeleteIcon />
                            </Button>
                          </TableCell>
                        </TableRow>
                      );
                    })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={data?.data.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      ) : (
        <Loading />
      )}
      {/* <Box sx={{ marginY: 5 }}>
        <Button
          variant="contained"
          color="success"
          onClick={() => navigate("/admin/new/teacher")}
          sx={{ fontSize: 20 }}
        >
          {t("create_new_account")}
        </Button>
      </Box> */}
    </AdminLayout>
  );
}
