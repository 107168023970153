import { Box, Paper, Typography } from "@mui/material";
import Cookies from "js-cookie";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { convertCurrency } from "../../../utils/convertCurrency";

export default function PayingTeacher({ teacher }) {
  const { t } = useTranslation();
  const { currency } = useSelector((state) => state.currency);
  const { conversionRate } = useSelector((state) => state.conversionRate);
      const [convertedAmount, setConvertedAmount] = React.useState(null);
      const [convertedAmount2, setConvertedAmount2] = React.useState(null);
      const [convertedAmount3, setConvertedAmount3] = React.useState(null);
      React.useEffect(() => {
        async function fetchConvertedAmount() {
          const result = await convertCurrency(teacher?.F2FSessionStd?.price,teacher?.F2FSessionStd?.currency,currency);
          const result2 = await convertCurrency(teacher?.F2FSessionTeacher?.price,teacher?.F2FSessionTeacher?.currency,currency);
          const result3 = await convertCurrency(teacher?.RemoteSession?.price,teacher?.RemoteSession?.currency,currency);
          setConvertedAmount(result);
          setConvertedAmount2(result2);
          setConvertedAmount3(result3);
        }
    
        fetchConvertedAmount();
      }, [teacher?.F2FSessionStd?.price,teacher?.RemoteSession?.price,teacher?.RemoteSession?.currency,teacher?.F2FSessionStd?.currency,teacher?.F2FSessionTeacher?.price,teacher?.F2FSessionTeacher?.currency,currency]);
  return (
    <Paper sx={{ padding: "32px 24px", marginY: "30px" }}>
      <Typography sx={{ fontSize: "22px", marginBottom: "16px" }}>
        {t("paying")}
      </Typography>
      {teacher?.F2FSessionStd && (
        <Typography sx={{ marginBottom: "8px" }}>
          {t("studenthome")} -{" "}
          {convertedAmount}{" "}
          {t(currency)}
        </Typography>
      )}
      {teacher?.F2FSessionTeacher && (
        <Typography sx={{ marginBottom: "8px" }}>
          {t("teacherhome")} -{" "}
          {convertedAmount2}{" "}
          {t(currency)}
        </Typography>
      )}
      {teacher?.RemoteSession && (
        <Typography>
          {t("onlineStudy")} -{" "}
          {convertedAmount3}{" "}
          {t(currency)}
        </Typography>
      )}
    </Paper>
  );
}
