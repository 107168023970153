import React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { Link } from "react-router-dom";
import { Box, Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import Loading from "../../components/Loading";
import Cookies from "js-cookie";
import EditIcon from "@mui/icons-material/Edit";
import { useState, useEffect } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import { useAllExamsAccepted } from "../../hooks/useAllExamsAccepted";

export default function ExamsCategoriesByAdmin() {
  const { t } = useTranslation();

  const columns = [
    { id: "photo", label: t("Photo"), minWidth: 150 },
    { id: "teacher", label: t("teacher"), minWidth: 150 },
    { id: "price", label: t("price"), minWidth: 150 },
    { id: "currency", label: t("currency"), minWidth: 150 },
    { id: "subject", label: t("subject"), minWidth: 150 },
    { id: "class", label: t("classes"), minWidth: 150 },
    { id: "semester", label: t("semester"), minWidth: 150 },
    { id: "studycurriculums", label: t("studycurriculums"), minWidth: 150 },
    { id: "Test documents", label: t("Test documents"), minWidth: 150 },
    { id: "update", label: t("update"), minWidth: 150 },
    { id: "delete", label: t("delete"), minWidth: 150 },
  ];

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const { data, isLoading } = useAllExamsAccepted();
  const [categories, setCategoires] = useState([]);

  useEffect(() => {
    if (data?.data) {
      setCategoires(data.data);
    }
  }, [data]);

  const { closeSnackbar, enqueueSnackbar } = useSnackbar();
  const lang = Cookies.get("i18next") || "en";

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };


  const navigate = useNavigate();
  const handleEdit = (id) => {
    navigate(`/admin/edit-exams/${id}`)
  };

  function filterTeachers(id) {
    const filteredTeachers = categories.filter(
      (teacher) => teacher.id.toString() !== id.toString()
    );
    setCategoires(filteredTeachers);
  }
  // Added by Abdelwahab
  const handleDelete = async (id) => {
    closeSnackbar();
    const isConfirmed = window.confirm(t("confirm_dangerous_action"));
    if (!isConfirmed) return;
    try {
      const res = await fetch(
        `${process.env.REACT_APP_API_KEY}api/v1/teacher/exams/delete-exam/${id}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json"
          },
        }
      );

      if (res.status !== 200) {
        enqueueSnackbar(t("The deletion process failed."), { variant: "error", autoHideDuration: 8000 });
      } else {
        enqueueSnackbar(t("Question deleted successfully"), { variant: "success", autoHideDuration: 8000 });
        filterTeachers(id)
      }

    } catch (err) {
      enqueueSnackbar(t("The deletion process failed."), { variant: "error", autoHideDuration: 8000 });
    }
  };



  return (
    <Box>
      {!isLoading ? (
        <Paper sx={{ width: "100%", padding: "20px" }}>
          <TableContainer sx={{ maxHeight: 440 }}>
            <Table stickyHeader aria-label="sticky table">
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={"center"}
                    style={{ top: 57, minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
              <TableBody>
                {categories
                  ?.map((row) => {
                    return (
                      <TableRow hover role="checkbox" key={row.id + "demj"}>
                        <TableCell align="center">
                          <img src={row?.image ? `${process.env.REACT_APP_API_KEY}images/${row.image}` : "/logo.png"} alt={row.image} width={"100px"} />
                        </TableCell>
                        <TableCell align="center">{row?.teacher?.firstName + " " + row?.teacher?.lastName}</TableCell>
                        <TableCell align="center">{row?.price}</TableCell>
                        <TableCell align="center">{t(row?.currency)}</TableCell>
                        <TableCell align="center">
                          {lang === "ar" ? row?.subject?.titleAR : row?.subject?.titleEN}
                        </TableCell>
                        <TableCell align="center">
                          {lang === "ar" ? row?.class?.titleAR : row?.class?.titleEN}
                        </TableCell>
                        <TableCell align="center">
                          {t(row?.semester)}
                        </TableCell>
                        <TableCell align="center">
                          {lang === "ar" ? row?.curriculums?.titleAR : row?.curriculums?.titleEN}
                        </TableCell>
                        {row?.docs ? <TableCell align="center">
                          <Button
                            component={Link}
                            to={`${process.env.REACT_APP_API_KEY}images/${row?.docs}`}
                            target="_blank" // يفتح الرابط في صفحة جديدة
                            rel="noopener noreferrer"
                          >
                            {t("Test documents")}
                          </Button>
                        </TableCell> : <>{t("No Documents")}</>}
                        <TableCell align="center">
                          <Button onClick={() => handleEdit(row.id)}>
                            <EditIcon />
                          </Button>
                        </TableCell>
                        <TableCell align="center">
                          <Button
                            color="error"
                            onClick={() => handleDelete(row.id)}
                          >
                            <DeleteIcon />
                          </Button>
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={categories.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      ) : (
        <Loading />
      )}
    </Box>
  );
}
