import { Button, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Avatar, Typography, Box } from '@mui/material';
import Cookies from 'js-cookie';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

function DiscountSingleTeacher({ teacher }) {
    const { t } = useTranslation();
    const lang = Cookies.get("i18next") || "en";
    const navigate = useNavigate();
    const { id } = useParams();

    return (
        <Paper sx={{ padding: "32px 24px", marginY: "30px" }}>
            <Typography sx={{ fontSize: "22px", marginBottom: "18px" }}>
                {t('Discounts')}
            </Typography>

            <Box sx={{ display: "flex", flexWrap: "wrap", gap: "16px", justifyContent: "center" }}>
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }}>
                        <TableHead>
                            <TableRow>
                                <TableCell align="center">{t('Photo')}</TableCell>
                                <TableCell align="center">{t('title')}</TableCell>
                                <TableCell align="center">{t('')}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {teacher ? (
                                teacher.map((row, index) => (
                                    <TableRow key={row.id || index}>
                                        <TableCell align="center">
                                            <Avatar
                                                src={`${process.env.REACT_APP_API_KEY}images/${row?.image}`}
                                                sx={{ width: "80px", height: "80px" }}
                                            />
                                        </TableCell>
                                        <TableCell align="center">
                                            {lang === "ar" ? row.titleArabic || t("No Title") : row.titleEnglish || t("No Title")}
                                        </TableCell>
                                        <TableCell align="center">
                                            <Button size="small" color="primary"
                                                onClick={() => navigate(`/resource/${id}/${row.id}`)}
                                            >
                                                {t("View more")}
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                ))
                            ) : (
                                <TableRow>
                                    <TableCell colSpan={3} align="center">
                                        <Typography
                                            sx={{
                                                fontSize: "16px",
                                                margin: "5px",
                                                padding: "10px",
                                                background: "#f8f9f9",
                                                textAlign: "center",
                                            }}
                                        >
                                            {t('There are no special discounts.')}
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
        </Paper>
    );
}

export default DiscountSingleTeacher;
