import React, { useState, useEffect } from 'react';
import AdminLayout from '../../components/admin/AdminLayout';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Button, CircularProgress, Container, Typography, Box, Alert } from '@mui/material';
import axios from 'axios';
import RefreshIcon from '@mui/icons-material/Refresh';

function AdminConnectWhatsApp() {
  const { t } = useTranslation();
  const [qrData, setQrData] = useState({ qr: null, isConnected: false });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [retryCount, setRetryCount] = useState(0);

  const fetchQRCode = async (signal) => {
    try {
      setLoading(true);
      setError(null);
      const response = await axios.get(`${process.env.REACT_APP_API_KEY}api/v1/whatsapp/status`, { signal });
      console.log(response);
      
      setQrData({
        qr: response.data.data.qr,
        isConnected: response.data.data.isConnected
      });
    } catch (error) {
      if (!axios.isCancel(error)) {
        setError(t('error.fetchQrFailed'));
        console.error("Error fetching QR Code:", error);
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const controller = new AbortController();
    const { signal } = controller;

    fetchQRCode(signal);

    // Polling كل 5 ثواني للتحديث التلقائي
    const interval = setInterval(() => {
      setRetryCount(prev => prev + 1);
      fetchQRCode(signal);
    }, 300000);

    return () => {
      controller.abort();
      clearInterval(interval);
    };
  }, []);

  const handleRetry = () => {
    setRetryCount(0);
    fetchQRCode();
  };

  return (
    <AdminLayout>
      <Container maxWidth="md">
        <Box sx={{ textAlign: 'center', mt: 4 }}>
          <Typography variant="h5" gutterBottom>
            {t('Link WhatsApp to Moalime Platform')}
          </Typography>

          {error && (
            <Alert severity="error" sx={{ mb: 2 }}>
              {error}
              <Button 
                color="inherit" 
                size="small" 
                endIcon={<RefreshIcon />}
                onClick={handleRetry}
                sx={{ ml: 2 }}
              >
                {t('common.retry')}
              </Button>
            </Alert>
          )}

          {qrData.isConnected ? (
            <Alert severity="success" sx={{ mb: 2 }}>
              {t('whatsapp.connectedSuccess')}
            </Alert>
          ) : loading ? (
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 2 }}>
              <CircularProgress />
              <Typography variant="body2" color="textSecondary">
                {t('common.loading')}
              </Typography>
            </Box>
          ) : qrData.qr ? (
            <Box sx={{ mt: 4 }}>
              <Typography variant="subtitle1" gutterBottom>
                {t('whatsapp scan Instructions')}
              </Typography>
              <img 
                src={qrData.qr} 
                alt="WhatsApp QR Code"
                style={{ 
                  maxWidth: '300px', 
                  height: 'auto', 
                  border: '1px solid #ddd',
                  borderRadius: '8px',
                  padding: '16px',
                  margin: '20px auto'
                }}
              />
              <Typography variant="body2" color="textSecondary">
                {t('The validity period of the verification code is 5 minutes (it will be automatically renewed every 5 minutes)')}
              </Typography>
            </Box>
          ) : (
            <Alert severity="warning">
              {t('WhatsApp number is available')}
            </Alert>
          )}
        </Box>
      </Container>
    </AdminLayout>
  );
}

export default AdminConnectWhatsApp;