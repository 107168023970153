import { Box, Button, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material'
import Cookies from 'js-cookie';
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom';
import { useCurriculums } from '../../../hooks/useCurriculums';
import { useClasses } from '../../../hooks/useClasses';
export function ExamSingleTeacher({  exams, subjects }) {
  const { t } = useTranslation()
      const { data: levelsData } = useClasses();
      const { data: curriculumsData } = useCurriculums();
  const {id}=useParams();
  
  let examsWithSubjects=[];
  if (exams) {
     examsWithSubjects = exams.map((exam) => {
    const subject = subjects.find((sub) => sub.id === parseInt(exam.subject));
    const classData = levelsData?.data.find((lev) => lev.id === parseInt(exam.class));
    const curriculums = curriculumsData?.data.find((curriculum) => curriculum.id === parseInt(exam.curriculums));
    return {
      ...exam,
      subjectTitleAR: subject?.titleAR || "غير معروف",
      subjectTitleEN: subject?.titleEN || "Unknown",
      classTitleAR: classData?.titleAR || "غير معروف",
      classTitleEN: classData?.titleEN || "Unknown",
      curriculumsTitleAR:curriculums?.titleAR || "غير معروف",
      curriculumsTitleEN: curriculums?.titleEN || "Unknown",

    };
  });
  }
  const navigate = useNavigate();
  const lang = Cookies.get("i18next") || "en";
  const handelTest =(testId)=>{
      navigate(`/request-test/${id}/${testId}`)
  }
  return (

    <Paper sx={{ padding: "32px 24px", marginY: "30px" }}>
      <Typography sx={{ fontSize: "22px", marginBottom: "18px" }}>
        {t('Exams')}
      </Typography>

      <Box sx={{ display: "flex", flexWrap: "wrap", gap: "16px", justifyContent: "center" }}>
        {exams ? (
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>{t("Photo")}</TableCell>
                  <TableCell>{t("subject")}</TableCell>
                  <TableCell>{t("level")}</TableCell>
                  <TableCell>{t("studycurriculums")}</TableCell>
                  <TableCell>{t("price")}</TableCell>
                  <TableCell>{t("Book a Exam")}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {examsWithSubjects.map((row) => (
                  <TableRow
                    key={row.name}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    
                    <TableCell align="center">
                      <img src={row.image ? `${process.env.REACT_APP_API_KEY}images/${row.image}` : "/logo.png"} alt={row.image} width={"80px"} />
                    </TableCell>
                    <TableCell>{lang==="ar"?row.subjectTitleAR:row.subjectTitleEN}</TableCell>
                    <TableCell>{lang==="ar"?row.classTitleAR:row.classTitleEN}</TableCell>
                    <TableCell>{lang==="ar"?row.curriculumsTitleAR:row.curriculumsTitleEN}</TableCell>
                    <TableCell>{row.price} {t(row.currency)}</TableCell>
                    <TableCell>
                      <Button size="small" color="primary" onClick={()=>handelTest(row.id)}>
                        {t("Book a Exam")}
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <Typography
            sx={{
              fontSize: "16px",
              margin: "5px",
              padding: "10px",
              background: "#f8f9f9",
              textAlign: "center",
            }}
          >
            {t('There are no special Exams.')}
          </Typography>
        )}
      </Box>
    </Paper>
  )
}

