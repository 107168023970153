import { Box, Typography, styled } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const Wrapper = styled(Box)({
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    color: "white",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "center",
    textAlign: "center",
    flexWrap: "wrap",  // جعل التصميم متجاوبًا
});

export default function JOBSection() {
  const { t } = useTranslation();

  return (
    <Wrapper>
      {/* إخفاء الصورة عند الشاشات الصغيرة */}
      <Box
        sx={{
          borderRadius: "12px",
          width: "600px",
          display: { xs: "none", md: "block" },
          marginBottom:{md:6,xs:10},
          marginTop:{md:5,xs:7}
        }}
      >
        <img src="https://server.moalime.com/drive/1.png" alt="Job" width="100%" />
      </Box>

      <Box sx={{ maxWidth: "500px", textAlign: "center" }}>
        <Typography
          sx={{
            marginTop: "10px",
            fontSize: { md: "32px", xs: "22px" },
            fontWeight: "bold",
            color: "#3B82F6ED",
          }}
        >
          {t("Your career opportunity starts here!")}
        </Typography>
        <Typography
          sx={{
            fontSize: { lg: "18px", md: "18px", xs: "14px" },
            fontWeight: "400",
            margin: "1.5rem 0",
            paddingX: "10px",
            color: "#000000",
          }}
        >
          {t(
            "Are you looking for a new job opportunity? The Moalime platform offers you a variety of opportunities in various fields. Additionally, you can now apply for available jobs through the advertisements displayed on our platform. Don't miss this opportunity and start building your professional future with Moalime today."
          )}
        </Typography>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            marginTop: "2rem",
          }}
        >
          <Link to="/careers">
            <Box
              sx={{
                minWidth: "150px",
                border: "1px solid",
                borderRadius: "5px",
                padding: "8px 12px",
                color: "#3B82F6ED",
                backgroundColor: "#FFFFFF",
                textAlign: "center",
              }}
            >
              {t("View jobs")}
            </Box>
          </Link>
        </Box>
      </Box>
    </Wrapper>
  );
}
