import React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { Box, Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import Loading from "../../components/Loading";
import Cookies from "js-cookie";
import EditIcon from "@mui/icons-material/Edit";
// import UpdateSubjectCategory from "./UpdateSubjectCategory";
import { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";

import DeleteIcon from "@mui/icons-material/Delete";
import { useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { useAllQuestions } from "../../hooks/useAllQuestions";
import { useNavigate } from "react-router-dom";

export default function QuestionsCategories() {
  const { t } = useTranslation();

  const columns = [
    { id: "lecture", label: t("Lecture"), minWidth: 150 },
    { id: "title", label: t("title"), minWidth: 150 },
    { id: "description", label: t("description"), minWidth: 150 },
    { id: "update", label: t("update"), minWidth: 150 },
    { id: "delete", label: t("delete"), minWidth: 150 },
  ];

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [searchInput, setSearchInput] = React.useState("");
  const { teacher } = useSelector((state) => state.teacher);
  const { data, isLoading } = useAllQuestions(teacher.id);
  const [categories, setCategoires] = useState([]);

  useEffect(() => {
    if (data?.data) {
      setCategoires(data.data);
    }
  }, [data]);

  const { token } = useSelector((state) => state.admin);
  const { closeSnackbar, enqueueSnackbar } = useSnackbar();
  const lang = Cookies.get("i18next") || "en";

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };


  const navigate = useNavigate();
  const handleEdit = (id) => {
    navigate(`/teacher/edit-questions/${id}`)
  };

  function filterTeachers(id) {
    const filteredTeachers = categories.filter(
      (teacher) => teacher.id.toString() !== id.toString()
    );
    setCategoires(filteredTeachers);
  }
  // Added by Abdelwahab
  const handleDelete = async (id) => {
    closeSnackbar();
    const isConfirmed = window.confirm(t("confirm_dangerous_action"));
    if (!isConfirmed) return;
    try {
      const res = await fetch(
        `${process.env.REACT_APP_API_KEY}api/v1/teacher/questions/delete-question/${id}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json"
          },
        }
      );

      if (res.status !== 200) {
        enqueueSnackbar(t("The deletion process failed."), { variant: "error", autoHideDuration: 8000 });
      } else {
        enqueueSnackbar(t("Question deleted successfully"), { variant: "success", autoHideDuration: 8000 });
        filterTeachers(id)
      }

    } catch (err) {
      enqueueSnackbar(t("The deletion process failed."), { variant: "error", autoHideDuration: 8000 });
    }
  };


  return (
    <Box>
      {!isLoading ? (
        <Paper sx={{ width: "100%", padding: "20px" }}>
          <TableContainer sx={{ maxHeight: 440 }}>
            <Table stickyHeader aria-label="sticky table">
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={"center"}
                    style={{ top: 57, minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
              <TableBody>
                {categories
                  ?.map((row) => {
                    return (
                      <TableRow hover role="checkbox" key={row.id + "demj"}>
                        {
                          lang === "ar" ?
                            <>
                              {row.lecture ? <><TableCell align="center">{row.lecture.titleArabic}</TableCell></> : <><TableCell align="center">{t("The Lecture has been deleted.")}</TableCell></>}
                              <TableCell align="center">{row.titleArabic}</TableCell>
                              <TableCell align="center">{row.descriptionArabic}</TableCell>

                            </>
                            :
                            <>
                              <TableCell align="center">{row.lecture.titleEnglish}</TableCell>
                              <TableCell align="center">{row.titleEnglish}</TableCell>
                              <TableCell align="center">{row.descriptionEnglish}</TableCell>

                            </>
                        }
                        <TableCell align="center">
                          <Button onClick={() => handleEdit(row.id)}>
                            <EditIcon />
                          </Button>
                        </TableCell>
                        <TableCell align="center">
                          <Button
                            color="error"
                            onClick={() => handleDelete(row.id)}
                          >
                            <DeleteIcon />
                          </Button>
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={categories.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      ) : (
        <Loading />
      )}
    </Box>
  );
}
