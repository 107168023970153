import React, { useState } from "react";
import { Box, Button, Paper, Tab } from "@mui/material";
import StudentLayout from "../../components/student/StudentLayout";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { useTranslation } from "react-i18next";
import BookedLesson from "../../components/student/BookedLesson";
import { usePastLessons } from "../../hooks/usePastLessons";
import { useSelector } from "react-redux";
import VideoCallIcon from "@mui/icons-material/VideoCall";
import Loading from "../../components/Loading";

const PastLessons = () => {
  const { t } = useTranslation();
  const [value, setValue] = useState("1");
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const { student } = useSelector((state) => state.student);

  const { data: pastLessons, isLoading: pastLessonsLoading } = usePastLessons(student?.id);


  return (
    <StudentLayout>
      <Paper sx={{ padding: "20px" }}>
        <Button
          href="http://meet.google.com/new"
          target="_blank"
          variant="contained"
          color="success"
          size="medium"
          endIcon={<VideoCallIcon sx={{ marginRight: "16px", fontSize: "large" }} />}
        >
          {t("start_lesson_meet")}
        </Button>
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList onChange={handleChange} aria-label="lab API tabs example">
              <Tab label={t("pastlessons")} value="1" />
            </TabList>
          </Box>
          <TabPanel value="1">
            {!pastLessonsLoading && pastLessons?.data?.length > 0 ?
              <>
              {pastLessons.data.map((lesson, index) => (
                <BookedLesson
                  key={`pastLesson-${lesson.id}`}
                  image={lesson.Teacher?.image}
                  name={`${lesson?.Teacher?.firstName} ${lesson?.Teacher?.lastName}`}
                  date={lesson?.date}
                  type={lesson?.type}
                  period={lesson?.period}
                  isStudent={true}
                  studentAccept={lesson?.studentAccept}
                  teacherAccept={lesson?.teacherAccept}
                  startedAt={lesson?.startedAt}
                  sessionId={lesson?.id}
                />
              ))}
              </>:
              <>
              <Loading />
              </>
            }
          </TabPanel>
        </TabContext>
      </Paper>
    </StudentLayout>
  );
};

export default PastLessons;

