import StudentLayout from '../../components/student/StudentLayout'
import React, { useEffect, useState } from "react";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { useSelector } from 'react-redux';
import axios from 'axios';
import Cookies from "js-cookie";
import { useTranslation } from 'react-i18next';
import { Button, Paper, Table, TableBody, TableCell, TableContainer, TablePagination, TableRow } from '@mui/material';
function MyBills() {
    const lang = Cookies.get("i18next") || "en";
    const { t } = useTranslation();
    const [sessions, setSessions] = useState([]);
    const { student } = useSelector((state) => state.student);
    useEffect(() => {
        const fetchBilling = async () => {
            try {
                const response = await axios.get(
                    `${process.env.REACT_APP_API_KEY}api/v1/student/get-bills-student/${student.id}`
                );

                console.log("Response Data:", response.data); // ✅ البيانات موجودة هنا
                setSessions(response.data.data);
            } catch (error) {
                console.error("Error fetching bills:", error);
            }
        };

        fetchBilling(); // ✅ استدعاء الدالة هنا

    }, [student]);

    function convertDate(dateString) {
        const date = new Date(dateString);
        return date.toISOString().split('T')[0]; // yyyy-mm-dd
    }

    const columns = [
        { id: "Invoice number", label: t("Invoice number"), minWidth: 150 },
        { id: "date", label: t("date"), minWidth: 150 },
        { id: "Session number", label: t("Session number"), minWidth: 150 },
        { id: "price", label: t("price"), minWidth: 150 },
        { id: "teacher", label: t("teacher"), minWidth: 150 },
        { id: "phone", label: t("phone"), minWidth: 150 },
        { id: "email", label: t("email"), minWidth: 150 },
        { id: "action", label: t("actions"), minWidth: 300 },
    ];

    const generatePDF = (session) => {
        const doc = new jsPDF();
        const categories = session.Teacher.TeacherSubjects.map(ts => ts.Subject.SubjectCategory);
        const uniqueCategories = Array.from(new Map(categories.map(cat => [cat.id, cat])).values());
        const teacherSubjects = uniqueCategories
            .map(ts => ts?.titleEN)
            .filter(Boolean)
            .join(", ");
        // تحميل اللوجو
        const logo = "/logo.png"; // استخدم مسار اللوجو في `public` أو رابط مباشر للصورة
        doc.addImage(logo, "PNG", 160, 7, 15, 15); // تحديد الموقع والحجم (X, Y, Width, Height)
    
        // إضافة اسم الموقع
        doc.setFont("helvetica", "bold");
        doc.setFontSize(15);
        doc.setTextColor(52, 152, 219);
        doc.text("Moalime", 20, 20);
    
        doc.setFontSize(16);
        doc.setTextColor(255, 0, 0);
        doc.text("Session Billing Report", 75, 30);
    
        doc.setFont("helvetica", "normal");
        doc.setTextColor(0, 0, 0);
        doc.setFontSize(13);
        
        let y = 50;
        
        // النص الذي يلتف (wrapping text) داخل العرض المحدد
        doc.text(`Your session with your teacher ${session?.Teacher.firstName} ${session?.Teacher.lastName} has been successfully booked.`, 14, y, {maxWidth: 180});
        y += 10;
        doc.text(`These are the specializations of the teacher who will give you the class`, 14, y, {maxWidth: 180});
        y += 10;
        doc.text(`( ${teacherSubjects} )`, 14, y, {maxWidth: 180});
        y += 20;
        doc.setFontSize(12);
        doc.text(`Invoice Number: ${session.id}`, 14, y);
        y += 10;
        doc.text(`Date: ${convertDate(session.date)}`, 14, y);
        y += 10;
        doc.text(`Session Number: ${session.period}`, 14, y);
        y += 10;
        doc.text(`Price: ${session.price} ${session.currency}`, 14, y);
        y += 10;
        doc.text(`Teacher: ${session.Teacher?.firstName || "N/A"} ${session.Teacher?.lastName || "N/A"}`, 14, y);
        y += 10;
        doc.text(`Teacher Phone: ${session.Teacher?.phone || "N/A"}`, 14, y);
        y += 10;
        doc.text(`Teacher Email: ${session.Teacher?.email || "N/A"}`, 14, y);
        y += 150;
        doc.text(`Moalime platform, I wish you success always`, 60, y);

        doc.save(`Session Billing Report.pdf`);
    };
    const printPDF = (session) => {
        const doc = new jsPDF();
        const categories = session.Teacher.TeacherSubjects.map(ts => ts.Subject.SubjectCategory);
        const uniqueCategories = Array.from(new Map(categories.map(cat => [cat.id, cat])).values());
        const teacherSubjects = uniqueCategories
            .map(ts => ts?.titleEN)
            .filter(Boolean)
            .join(", ");
        console.log(teacherSubjects);
    
        // تحميل اللوجو
        const logo = "/logo.png"; // استخدم مسار اللوجو في `public` أو رابط مباشر للصورة
        doc.addImage(logo, "PNG", 160, 7, 15, 15); // تحديد الموقع والحجم (X, Y, Width, Height)
    
        // إضافة اسم الموقع
        doc.setFont("helvetica", "bold");
        doc.setFontSize(15);
        doc.setTextColor(52, 152, 219);
        doc.text("Moalime", 20, 20);
    
        doc.setFontSize(16);
        doc.setTextColor(255, 0, 0);
        doc.text("Session Billing Report", 75, 30);
    
        doc.setFont("helvetica", "normal");
        doc.setTextColor(0, 0, 0);
        doc.setFontSize(13);
        
        let y = 50;
        
        // النص الذي يلتف (wrapping text) داخل العرض المحدد
        doc.text(`Your session with your teacher ${session?.Teacher.firstName} ${session?.Teacher.lastName} has been successfully booked.`, 14, y, {maxWidth: 180});
        y += 10;
        doc.text(`These are the specializations of the teacher who will give you the class`, 14, y, {maxWidth: 180});
        y += 10;
        doc.text(`( ${teacherSubjects} )`, 14, y, {maxWidth: 180});
        y += 20;
        doc.setFontSize(12);
        doc.text(`Invoice Number: ${session.id}`, 14, y);
        y += 10;
        doc.text(`Date: ${convertDate(session.date)}`, 14, y);
        y += 10;
        doc.text(`Session Number: ${session.period}`, 14, y);
        y += 10;
        doc.text(`Price: ${session.price} ${session.currency}`, 14, y);
        y += 10;
        doc.text(`Teacher: ${session.Teacher?.firstName || "N/A"} ${session.Teacher?.lastName || "N/A"}`, 14, y);
        y += 10;
        doc.text(`Teacher Phone: ${session.Teacher?.phone || "N/A"}`, 14, y);
        y += 10;
        doc.text(`Teacher Email: ${session.Teacher?.email || "N/A"}`, 14, y);
        y += 150;
        doc.text(`Moalime platform, I wish you success always`, 60, y);
    
        doc.autoPrint();
        window.open(doc.output("bloburl"), "_blank");
    };
    

    return (
        <StudentLayout>
            <div >
                <h2 className="text-xl font-bold mb-4">{t("Billing Report")}</h2>
            </div>
            <Paper sx={{ width: "100%", padding: "20px" }}>
                <TableContainer sx={{ maxHeight: 440 }}>
                    <Table stickyHeader aria-label="sticky table">
                        <TableRow>
                            {columns.map((column) => (
                                <TableCell
                                    key={column.id}
                                    align={"center"}
                                    style={{ top: 57, minWidth: column.minWidth }}
                                >
                                    {column.label}
                                </TableCell>
                            ))}
                        </TableRow>
                        <TableBody>
                            {sessions?.map((row) => {
                                return (
                                    <TableRow hover role="checkbox" key={row.id + "demj"}>
                                        <TableCell align="center">{row.id}</TableCell>
                                        <TableCell align="center">{convertDate(row.date)}</TableCell>
                                        <TableCell align="center">{row.period}</TableCell>
                                        <TableCell align="center">{row.price} {" "}{t(row.currency)}</TableCell>
                                        <TableCell align="center">{row.Teacher?.firstName || "N/A"} {row.Teacher?.lastName || "N/A"}</TableCell>
                                        <TableCell align="center">{row.Teacher?.phone}</TableCell>
                                        <TableCell align="center">{row.Teacher?.email}</TableCell>
                                        <TableCell align="center">
                                            <div className="flex space-x-2 justify-between">
                                                <Button
                                                    onClick={() => generatePDF(row)}
                                                    className="bg-blue-500 text-white p-2 rounded m-3"
                                                    variant="contained"
                                                >
                                                    {t("Save as PDF")}
                                                </Button>
                                                <Button
                                                    onClick={() => printPDF(row)}
                                                    className="bg-green-500 text-white w-28 py-2 rounded"
                                                    variant="outlined"
                                                >
                                                    {t("Print")}
                                                </Button>
                                            </div>
                                        </TableCell>


                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>

            </Paper>
        </StudentLayout>
    )
}

export default MyBills
