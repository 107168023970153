import { Paper, Typography, Box, Button } from '@mui/material'
import React, { useEffect, useState } from 'react'
import StudentLayout from '../../components/student/StudentLayout'
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import 'react-phone-input-2/lib/style.css'
import { useRequestLession } from '../../hooks/useRequestLession';
import Loading from '../../components/Loading';
import { useNavigate } from 'react-router-dom';

export default function StudentLession() {
    const { t } = useTranslation();
    const { student} = useSelector(s => s.student);
    const { data, isLoading } = useRequestLession(student?.id)
    const [lession, setlession] = useState([])
    const navigate = useNavigate();


    useEffect(() => {
        if (data) {
            setlession(data?.data)
        }
    }, [data])

    async function handlePayment(id) {
        navigate(`/student/PayLesson/${id}`)
      }
      const handleDalete = async(id) => {
        try {
          const response = await fetch(`${process.env.REACT_APP_API_KEY}api/v1/lesson/${id}`, {
            method: "DELETE",
            headers: {
              "Content-Type": "application/json",
            },
          });
          if (!response.ok) {
            throw new Error(`Error: ${response.status}`);
          }
          const data = await response.json();
          console.log(data);
          setlession(lession.filter((item) => item.id!== id));
        } catch (error) {
          console.error("Error deleting lesson:", error);
        }
        
      }
    return (
        <StudentLayout>
            <Paper sx={{ padding: "20px" }}>
                <Typography sx={{ fontSize: "24px", marginTop: "12px", fontWeight: "600", marginBottom: "30px" }}>
                    {t('Lesson booking requests')}
                </Typography>
                {!isLoading?
                <>
                    {lession.map((item, index) => 
                        <Box key={index} sx={{ marginBottom: "12px",background:"#f2f3f4",padding:"1rem" }}>
                            <Box sx={{ marginBottom: "6px" }}>
                                <Typography variant="h6" className='flex justify-end items-center' >
                                    {item.teacher?.firstName} {item.teacher?.lastName} 
                                    <img src={item.teacher?.image?`${process.env.REACT_APP_API_KEY}images/${item.teacher?.image}` : "/logo.png"} alt={item.teacher?.image}  style={{borderRadius:"50%",height:"80px",width:"80px",marginRight:"1rem"}}/>
                                </Typography>
                                <Box className="flex justify-around">
                                <Typography  gutterBottom className='border px-3 py-2 rounded w-52 text-3xl text-center'>
                                    {t("Time")}{" : "} {new Date(item?.date).toLocaleTimeString()}
                                </Typography>
                                <Typography  gutterBottom className='border px-3 py-2 rounded w-52 text-3xl text-center'>
                                    {t("day")}{" : "} {new Date(item?.date).toLocaleDateString()}
                                </Typography>
                                </Box>
                                <Box className="flex justify-around">
                                <Typography  gutterBottom className='border px-3 py-2 rounded w-52 text-3xl text-center'>
                                    {t("status")}{" : "} {
                                        item?.status === 'pending'? <span className='text-[#f4d03f]'>{t('pending') }</span>: (item?.status === 'approved'?<span className='text-[#2ecc71]'>{t('approved') }</span>:<span className='text-[#e74c3c]'>{t('canceled') }</span>)
                                    } 
                                </Typography>
                                <Typography  gutterBottom className='border px-3 py-2 rounded w-52 text-3xl text-center' >
                                    {t("lessonType")}{" : "} {t(item?.type)}
                                </Typography>
                                </Box>
                                <Box className="flex justify-around">
                                <Typography  gutterBottom className='border px-3 py-2 rounded w-52 text-3xl text-center'>
                                    {t("Session number")}{" : "} {item?.period} 
                                </Typography>
                                <Typography  gutterBottom className='border px-3 py-2 rounded w-52 text-3xl text-center'>
                                    {t("price")}{" : "} {item?.price } {" "} {t(item?.currency)} 
                                </Typography>
                                </Box>
                            </Box>
                            <Box className="flex justify-around mt-5">
                            {item?.status === "approved" && (
                                item?.paymentLink ? <Button variant="contained" color="primary" onClick={() => handlePayment(item?.id)}>{t("You can now pay your reservation")}</Button>:<div className='text-[#e74c3c] my-3'>{t("Sorry, I was late for the payment. You can book again.")}</div>
                            )}
                             <Button variant="contained" color="error" onClick={()=>handleDalete(item.id)}>{t("delete")}</Button>
                            </Box>
                        </Box> )}  
                                  
                </>:<> <Loading /> </>}
            </Paper>
        </StudentLayout>
    )
}
