import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage"; // إضافة الاستيراد للتخزين

const firebaseConfig = {
  apiKey: "AIzaSyDgCmRAtjY-jqVRmHb4W_dlmkJoZnwbZs8",
  authDomain: "moalemy-54d19.firebaseapp.com",
  projectId: "moalemy-54d19",
  storageBucket: "moalemy-54d19.appspot.com",
  messagingSenderId: "239715172398",
  appId: "1:239715172398:web:79534f914a21f869c11fbf",
  measurementId: "G-51Y8PNXGK8"
};

// تهيئة تطبيق Firebase
const app = initializeApp(firebaseConfig);

// إعداد Firestore و Firebase Storage
export const db = getFirestore(app);
export const storage = getStorage(app); // تفعيل Firebase Storage
