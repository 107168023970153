import * as React from 'react';
import { t } from "i18next";
import Cookies from "js-cookie";
import { Card, CardMedia } from '@mui/material';
import Navbar from '../../Navbar';
import { usePackages } from '../../../hooks/usePackages';
import { Box, Grid } from '@mui/material'
import { Link } from 'react-router-dom'
import NotFound from '../../reusableUi/Not Found';


function Packages() {
  const { data } = usePackages();
  const lang = Cookies.get("i18next") || "en";

  return (
    <Navbar>
      <Box sx={{
        width: '100%',
        padding: '20px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '20px',
        marginBottom: '20px',
        paddingTop: '9rem',
        paddingBottom: '10px',
      }}>
        {/* title */}
        <Box sx={{
          fontSize: '2rem',
          fontWeight: 'bold',
          color: '#3B82F6FC',
        }}>
          {t("School packages for school students")}
        </Box>
        {/* description */}
        <Box sx={{
          fontSize: '1rem',
          fontWeight: '300',
          color: '#000',
        }}>
          {t("Ensure your children's excellence through our monthly packages that provide daily follow-up with certified teachers and periodic reports on their performance.")}
        </Box>
        {/* data */}
        {!data?<NotFound text={t("Unfortunately, there are currently no packages available.")}/>:(
                  <Grid container spacing={2} sx={{ padding: 2, justifyContent: "center", alignItems: "center" }}>
                  {data?.data.map((row) => (
                    <Grid item xs={12} sm={6} md={4} lg={3} key={row.id}> {/* أضف key هنا */}
                      <Card>
                        <CardMedia
                          component="img"
                          image={row.image ? `${process.env.REACT_APP_API_KEY}images/${row.image}` : "/logo.png"}
                          alt="package img"
                          sx={{ filter: "brightness(50%)", height: "180px" }}
                        />
                        <Box sx={{
                          display: 'flex',
                          justifyContent: "center",
                          alignItems: "center",
                          gap: "10px",
                          padding: "0 20px",
                          fontSize: "0.9rem",
                          fontWeight: "bold",
                          color: "#000",
                          marginTop: "1rem",
                          marginBottom: "2rem",
                        }}>
                          {lang === "ar" ?
                            <>{row?.titleAr}</>
                            :
                            <>{row?.titleEn}</>
                          }      </Box>
                        <Box sx={{
                          display: 'flex',
                          justifyContent: "center",
                          alignItems: "center",
                          gap: "10px",
                          fontSize: "0.9rem",
                          fontWeight: "bold",
                          color: "#000",
                          marginTop: "1rem",
                          marginBottom: "2rem",
                        }}>
                          <Link to={`/teacher/${row.teacherId}/${row.id}`}>
                            <Box
                              sx={{
                                width: "120px",
                                border: "1px solid",
                                borderRadius: "5px",
                                padding: "8px 12px",
                                color: "#FFF",
                                backgroundColor: "#3B82F6ED",
                                textAlign: "center",
                              }}
                            >
                              {t("View more")}
                            </Box>
                          </Link>
                        </Box>
                      </Card>
                    </Grid>
                  ))}
                </Grid>
        )}
      </Box>
    </Navbar>
  )
}

export default Packages;
