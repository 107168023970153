import {
    Box,
    Button,
    Container,
    DialogActions,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import Cookies from "js-cookie";
import { useSelector } from "react-redux";
import Loading from "../Loading";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import Navbar from "../Navbar";
import { useAllLectures } from "../../hooks/useAllLectures";

export default function EditQuestion() {
    const { t } = useTranslation();
    const { id } = useParams()
    const { closeSnackbar, enqueueSnackbar } = useSnackbar();
    const { teacher } = useSelector((state) => state.teacher);
    const [lectures, setLectures] = useState([])
    const [question, setQuestion] = useState()


    const {
        register,
        control,
        formState: { errors },
        handleSubmit,
        reset
    } = useForm({
        defaultValues: {
            lectureId: "",
            titleArabic: "",
            titleEnglish: "",
            descriptionArabic: "",
            descriptionEnglish: ""
        },
    });
    useEffect(() => {
        const fetchQuestion = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_KEY}api/v1/teacher/questions/${id}`);
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                const data = await response.json();
                setQuestion(data.data);
            } catch (err) {
                console.error("Error fetching levels:", err);
            }
        };
        fetchQuestion()

    }, [id]);

    useEffect(() => {
        if (question) {
            reset({
                lectureId: question.lectureId,
                titleArabic: question.titleArabic,
                titleEnglish: question.titleEnglish,
                descriptionArabic: question.descriptionArabic,
                descriptionEnglish: question.descriptionEnglish
            });
        }
    }, [question, reset]);
    const { data, isLoading } = useAllLectures(teacher.id);

    useEffect(() => {
        if (data?.data) {
            setLectures(data.data);
        }
    }, [data]);
    const navigate = useNavigate();
    const lang = Cookies.get("i18next") || "en";

    async function onSubmit(data) {
        closeSnackbar();
        const formData = new FormData();

        formData.append("teacherId", teacher.id);
        for (const key in data) {
            if (key !== "teacherId") {
                formData.append(key, data[key]);
            }
        }

        try {
            await axios.put(
                `${process.env.REACT_APP_API_KEY}api/v1/teacher/questions/edit-question/${id}`,
                formData,
                {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                }
            );

            enqueueSnackbar(t("Question has been updated."), { variant: "success", autoHideDuration: 8000 });
            navigate("/teacher/questions")
        } catch (err) {
            enqueueSnackbar(t("Something went wrong"), { variant: "error", autoHideDuration: 8000 });
        }
    }
    
   const handleClose=()=>{
    navigate("/teacher/questions")
   }
    return (
        <>
            <Navbar>
                {!isLoading ? <>
                    <Container sx={{ marginTop: '120px', marginBottom: '80px' }}>
                        <h1 className="text-3xl text-center my-4">{t("Edit Question")}</h1>
                        <form style={{ maxWidth: "50%", margin: 'auto' }} encType="multipart/form-data" onSubmit={handleSubmit(onSubmit)}>
                            <Box sx={{ marginBottom: "18px" }}>
                                <InputLabel sx={{ marginBottom: "6px", fontSize: "14px" }}>
                                    {t("Select Lecture")}
                                </InputLabel>
                                <Controller
                                    name="lectureId"
                                    control={control}
                                    render={({ field }) => (
                                        <Select
                                            {...field}
                                            fullWidth
                                            {...register("lectureId", {
                                                required: t("isRequired"),
                                            })}
                                        >
                                            {lang === "ar"
                                                ? lectures.map((lecture, index) => (
                                                    <MenuItem key={index} value={lecture.id}>{t(lecture.titleArabic)}</MenuItem>
                                                ))
                                                : lectures.map((lecture, index) => (
                                                    <MenuItem key={index} value={lecture.id}>{t(lecture.titleEnglish)}</MenuItem>
                                                ))
                                            }
                                        </Select>
                                    )}
                                    rules={{ required: t("required") }}
                                />

                            </Box>
                            <Box sx={{ marginBottom: "18px" }}>
                                <InputLabel sx={{ marginBottom: "6px", fontSize: "14px" }}>
                                    {t("titleAr")}
                                </InputLabel>
                                <Controller
                                    name="titleArabic"
                                    control={control}
                                    render={({ field }) => <TextField {...field} fullWidth />}
                                    {...register("titleArabic", {
                                        required: "title Address is required",
                                    })}
                                />
                                {errors.titleArabic?.type === "required" && (
                                    <Typography
                                        color="error"
                                        role="alert"
                                        sx={{ fontSize: "13px", marginTop: "6px" }}
                                    >
                                        {t("required")}
                                    </Typography>
                                )}
                            </Box>

                            <Box sx={{ marginBottom: "18px" }}>
                                <InputLabel sx={{ marginBottom: "6px", fontSize: "14px" }}>
                                    {t("titleEn")}
                                </InputLabel>
                                <Controller
                                    name="titleEnglish"
                                    control={control}
                                    render={({ field }) => <TextField {...field} fullWidth />}
                                    {...register("titleEnglish", {
                                        required: "title Address is required",
                                    })}
                                />
                                {errors.titleEnglish?.type === "required" && (
                                    <Typography
                                        color="error"
                                        role="alert"
                                        sx={{ fontSize: "13px", marginTop: "6px" }}
                                    >
                                        {t("required")}
                                    </Typography>
                                )}
                            </Box>

                            <Box sx={{ marginBottom: "18px" }}>
                                <InputLabel sx={{ marginBottom: "6px", fontSize: "14px" }}>
                                    {t("descriptionQuestionArabic")}
                                </InputLabel>
                                <Controller
                                    name="descriptionArabic"
                                    control={control}
                                    render={({ field }) => <TextField multiline rows={4} {...field} fullWidth />}
                                    {...register("descriptionArabic", {
                                        required: "description Address is required",
                                    })}
                                />
                                {errors.descriptionArabic?.type === "required" && (
                                    <Typography
                                        color="error"
                                        role="alert"
                                        sx={{ fontSize: "13px", marginTop: "6px" }}
                                    >
                                        {t("required")}
                                    </Typography>
                                )}
                            </Box>

                            <Box sx={{ marginBottom: "18px" }}>
                                <InputLabel sx={{ marginBottom: "6px", fontSize: "14px" }}>
                                    {t("descriptionQuestionEnglish")}
                                </InputLabel>
                                <Controller
                                    name="descriptionEnglish"
                                    control={control}
                                    render={({ field }) => <TextField multiline rows={4} {...field} fullWidth />}
                                    {...register("descriptionEnglish", {
                                        required: "description Address is required",
                                    })}
                                />
                                {errors.descriptionEnglish?.type === "required" && (
                                    <Typography
                                        color="error"
                                        role="alert"
                                        sx={{ fontSize: "13px", marginTop: "6px" }}
                                    >
                                        {t("required")}
                                    </Typography>
                                )}
                            </Box>
                            <DialogActions>
                                <Button
                                    variant="contained"
                                    type="submit"
                                    sx={{ ml: "6px", mr: "6px" }}
                                >
                                    {t("edit")}
                                </Button>
                                <Button onClick={handleClose} color="error">
                                    {t("cancel")}
                                </Button>
                            </DialogActions>
                        </form>

                    </Container>
                </> : <>
                    <Loading />
                </>}


            </Navbar>

        </>
    );
}
