import React, { useEffect, useState } from 'react';
import StudentLayout from '../../components/student/StudentLayout';
import instance from '../../axios/instance';
import { useSelector } from 'react-redux';
import { Divider, styled } from '@mui/material';
import { t } from 'i18next';
import Loading from '../../components/Loading';
import Cookies from "js-cookie";
import QuestionsDetails from '../../components/reusableUi/QuestionsDetails';

const Root = styled('div')(({ theme }) => ({
  width: '100%',
  ...theme.typography.body2,
  color: theme.palette.text.secondary,
  '& > :not(style) ~ :not(style)': {
    marginTop: theme.spacing(2),
  },
}));

function StudentQuestion() {
  const lang = Cookies.get("i18next") || "en";
  const { student } = useSelector(state => state.student);
  const [data, setData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await instance(`student/getLecturesWithQuestions/${student.id}`);
        setData(res.data.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [student.id]);

  // if (data === null) return <Loading />;
  
  if (data === null) {
    return <StudentLayout><h1 className='text-center text-2xl text-sky-500 mt-20'>{t("Currently unavailable Questions")}</h1></StudentLayout>;
  }

  return (
    <StudentLayout>
      <Root>
        {data.map((item) => (
          <React.Fragment key={item.Id}>
            <section className='grid grid-cols-3 gap-4'>
              <div className='col-span-2'>
                <h1 className='text-2xl'>
                  {lang === "ar" ? item.nameAR : item.nameEN}
                </h1>
              </div>
              <QuestionsDetails id={item.Id} nameEN={item.nameEN } nameAR={item.nameAR } />
            </section>
            <Divider />
          </React.Fragment>
        ))}
      </Root>
    </StudentLayout>
  );
}

export default StudentQuestion;
